import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import accountReducer from '../slices/account';
import streamEventReducer from '../slices/streamEvent';
import styleReducer from '../slices/style';
import { createReduxHistoryContext, reachify } from "redux-first-history";
import { createWouterHook } from "redux-first-history/wouter";
import { createBrowserHistory } from 'history';
import {psApi} from "../api/psApi";
import {listChatApi} from "../api/chatApi";

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history: createBrowserHistory(),
  //other options if needed 
});

export const store = configureStore({
  reducer: {
    api: psApi.reducer,
    hubChat: listChatApi.reducer,
    router: routerReducer,
    account: accountReducer,
    streamEvent: streamEventReducer,
    style: styleReducer
  },
  middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat([routerMiddleware, psApi.middleware, listChatApi.middleware])
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>>;

export const history = createReduxHistory(store);
//if you use @reach/router 
export const reachHistory = reachify(history);
//if you use wouter
export const wouterUseLocation = createWouterHook(history);