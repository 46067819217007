import React, {useState} from "react";
import {LoginForm} from "./LoginForm";
import {Link} from "react-router-dom";
import {linkStyle} from "../styles/baseStyles";

export function Login() {
    return (
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", height: "100%" }}>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%" }}>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", rowGap: "calc(0.5vw + 5px)" }}>
                    <LoginForm style={{  }} />
                    <div style={{ 
                        alignSelf: "flex-end",
                        display: "flex", 
                        flexDirection: "row-reverse", 
                        alignItems: "center", 
                        columnGap: "calc(0.5vw + 5px)" 
                    }}>
                        <div style={{}}>No account? <Link style={{ ...linkStyle }} to={"/register"}>register here</Link> </div>
                        <div style={{}}><Link style={{ ...linkStyle }} to={"/account/recover"}>forgot password?</Link> </div>
                    </div>
                </div>
            </div>
        </div>
    );
}