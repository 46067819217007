import React from "react";
import {useAppDispatch, useAppSelector} from "../app/hooks";
import {colorIndexSelector, currentColors, updateColor} from "../slices/style";
import {Link, NavLink} from "react-router-dom";
import {activeLinkStyle, linkStyle} from "../styles/baseStyles";
import {ReactComponent as MainIcon} from "../icons/mainIcon.svg";
import {ReactComponent as UserIcon} from "../icons/userIcon.svg";
import {
    useCurrentAccountQuery,
    useCurrentLoginQuery,
    useListGroupByAccountQuery,
    useLogoutMutation
} from "../api/psApi";
import {Loader} from "./Loader";
import {isAuthenticated} from "../slices/account";
import Switch from "react-switch";

export function AppHead({ style }: { style?: React.CSSProperties}) {
    const colors = useAppSelector(currentColors);
    const dispatch = useAppDispatch();
    const { data: listGroupData, 
        isLoading: listGroupIdLoading, 
        isFetching: listGroupIdFetching } = useListGroupByAccountQuery();
    const { data: login, isLoading: loginLoading, isFetching: loginFetching } = useCurrentLoginQuery();
    const [ logoutPost, logoutState ] = useLogoutMutation();
    const { data: currentAccount, 
        isLoading: currentAccountLoading, 
        isFetching: currentAccountFetching 
    } = useCurrentAccountQuery();
    const colorIndex = useAppSelector(colorIndexSelector);
    
    return (
        <div style={{display: "flex", flexDirection: "row", alignItems: "center", ...style}}>
            <div style={{flex: 1, display: "flex", flexDirection: "row", columnGap: "calc(1vw + 10px)", alignItems: "center" }}>
                <Link style={{ ...linkStyle, 
                    display: "flex", 
                    flexDirection: "row",
                    columnGap: "calc(0.5vw + 5px)",
                    fontSize: "calc(1vw + 10px)",
                    paddingRight: "calc(1vw + 10px)",
                    alignItems: "center" }} to={"/"}>
                    {<MainIcon style={{ height: "calc(0.8vw + 10px)", width: "calc(0.8vw + 10px)" }} />}
                    Pattaya Girls
                </Link>
                <Link style={{ ...linkStyle,
                    fontSize: "calc(0.8vw + 8px)",
                    alignItems: "center" }} to={"/streams"}>
                    Live streams
                </Link>
                {listGroupData
                && listGroupData.map(group => <NavLink 
                    key={group.groupId} 
                    style={({ isActive  }) => ({
                        ...(isActive ? activeLinkStyle : linkStyle),
                        alignItems: "center",
                        fontSize: "calc(0.8vw + 8px)"
                    })} to={`/group/mp/${group.groupId}`}>
                    {group.name}
                </NavLink>)}
            </div>
            <div style={{
                flex: "0 0 auto",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                columnGap: "calc(1vw + 8px)"
            }}>
                <Switch checked={colorIndex === 1}
                        title={"Dark mode"}
                        onColor={colors.text4}
                        onChange={(val) => dispatch(updateColor(colorIndex === 0 ? 1 : 0))} />
                {loginLoading && <Loader height={"30px"} width={"30px"} />}
                {<React.Fragment>
                    {isAuthenticated(login) && <React.Fragment>
                        <Link to={"/account"} style={{
                            ...linkStyle,
                            fontSize: "calc(1vw + 10px)",
                            fontWeight: "bold"
                        }}>
                            <UserIcon style={{ height: "1em", width: "1em", paddingRight: "calc(2px + 0.2vw)" }} />
                            {currentAccount && currentAccount.username}
                        </Link>
                        <div className={"hover-azure"} style={{
                            ...linkStyle,
                            fontSize: "calc(1vw + 10px)",
                            fontWeight: "bold"
                        }} onClick={(e) => logoutPost()}>Logout</div>
                    </React.Fragment>}
                    {!isAuthenticated(login) && <React.Fragment>
                        <Link to={"/login"} style={{
                            ...linkStyle,
                            fontSize: "calc(1vw + 10px)",
                            fontWeight: "bold"
                        }}>Login</Link>
                        <Link to={"/register"} style={{
                            ...linkStyle,
                            fontSize: "calc(1vw + 10px)",
                            fontWeight: "bold"
                        }}>Register</Link>
                    </React.Fragment>}
                </React.Fragment>}
                
            </div>
        </div>
    );
}