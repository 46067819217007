import React, {useState} from "react";
import {
    StreamEventModel,
    useListLadyByStreamEventIdQuery,
    useStopStreamEventMutation,
    useUpdateStreamEventMutation
} from "../api/psApi";
import StreamEventView from "./StreamEventView";
import {buttonStyle} from "../styles/baseStyles";
import {UpdateStreamEventForm} from "./UpdateStreamEventForm";
import {Loader} from "./Loader";


export function GroupCurrentLiveStream({ streamEvent }: { streamEvent: StreamEventModel }) {
    const [ stopStream, stopStreamStatus ] = useStopStreamEventMutation();
    const [ updateStream, updateStreamStatus ] = useUpdateStreamEventMutation();
    const {
        data: ladies,
        isLoading: ladiesIsLoading,
        isFetching: ladiesIsFetching
    } = useListLadyByStreamEventIdQuery({ streamEventId: streamEvent.streamEventId, current: true });
    
    if(ladiesIsLoading || stopStreamStatus.isLoading){
        return (<Loader height={"10vw"} width={"10vw"} />);
    }
    if(!ladies){
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", height: "100%" }}>
            <div style={{ width: "100%", textAlign: "center", fontSize: "2vw" }}>
                Cannot fetch ladies
            </div>
        </div>
    }
    
    return (
        <div style={{ 
            display: "flex",
            flexDirection: "row",
            alignItems: "stretch",
            flexWrap: "wrap",
            columnGap: "1vw",
            paddingBottom: "2vw"
        }}>
            {streamEvent.type !== "Offsite"
            && <div style={{ flex: "0.5", minWidth: "" }}>
                <StreamEventView stream={streamEvent} withChat={false} withOrder={false} />
            </div>}
            <div style={{ 
                flex: "0.5", minWidth: "800px"
                , display: "flex", flexDirection: "column"
                , alignItems: "stretch"
                , rowGap: "2vw"
                , padding: "2.2vw 2vw 0vw 0vw" 
            }}>
                <UpdateStreamEventForm
                    style={{ flex: "0 0 auto" }}
                    streamEvent={streamEvent}
                    currentLadiesId={ladies.map(x => x.ladyId)}
                    currentStreamLinkIds={[]}
                    onSubmit={(values) => {
                        updateStream({ updateStreamEventModel: values });
                    }} 
                    submitText={"Update"} />
                <div style={{ flex: "1" }}>
                </div>
                <button style={{ ...buttonStyle, flex: "0 0 auto", fontSize: "2vw", gridRow: "2 / 3", gridColumn: "2 / 3" }}
                        onClick={(e) => stopStream({ stopStreamEventModel: { streamEventId: streamEvent.streamEventId } })}>
                    Stop Stream
                </button>
            </div>

        </div>
    );
}