import React, {useState} from "react";
import {useParams} from "react-router-dom";
import {
    useCreateLadyPayMutation,
    useCreatePaymentOrderMutation,
    useDeleteLadyPayMutation, useDeletePaymentOrderByPaymentIdMutation,
    useListLadyPayByDayQuery,
    useListPaymentOrderQuery
} from "../api/psApi";
import { addHours, startOfDay } from "date-fns";
import {PaymentOrderTable} from "./PaymentOrderTable";
import {Loader} from "./Loader";
import {smTitleStyle} from "../styles/baseStyles";
import {PaymentOrderForm} from "./PaymentOrderForm";
import {useAppSelector} from "../app/hooks";
import {currentColors} from "../slices/style";
import {DatePicker} from "./DatePicker";
import {PaymentTypeTotalTable} from "./PaymentTypeTotalTable";
import {LadiesPayTable} from "./LadiesPayTable";

export function GroupPaymentPortal({} : {}) {
    const {groupId} = useParams();
    const colors = useAppSelector(currentColors);
    const [dayFilter, updateDayFilter] = useState(startOfDay(addHours(new Date(), -12)));

    const fromDate = addHours(dayFilter, 12);
    const toDate = addHours(dayFilter, 36);
    const isToday = startOfDay(addHours(new Date(), -12)).getTime() === dayFilter.getTime();

    const {
        data: dayPaymentOrders,
        isLoading: dayPaymentOrdersIsLoading,
        isFetching: dayPaymentOrdersIsFetching
    } = useListPaymentOrderQuery({
        groupId: parseInt(groupId),
        fromDate: fromDate.toISOString(),
        toDate: toDate.toISOString()
    })

    const {
        data: dayLadyPays,
        isLoading: dayLadyPaysIsLoading,
        isFetching: dayLadyPaysIsFetching
    } = useListLadyPayByDayQuery({
        groupId: parseInt(groupId),
        dayDate: dayFilter.toISOString()
    })

    const [createPaymentOrderPost, createPaymentOrderState] = useCreatePaymentOrderMutation();
    const [deleteLadyPayPost, deleteLadyPayState] = useDeleteLadyPayMutation();
    const [createLadyPayPost, createLadyPayState] = useCreateLadyPayMutation();
    const [deletePaymentOrderPost, deletePaymentOrderState] = useDeletePaymentOrderByPaymentIdMutation();

    if (dayPaymentOrdersIsLoading
        || dayLadyPaysIsLoading) {
        return (
            <Loader height={"300px"} width={"300px"}/>
        );
    }

    if (!dayPaymentOrders || !dayLadyPays) {
        return (<div style={{display: "flex", flexDirection: "row", alignItems: "center", height: "100%"}}>
            <div style={{width: "100%", textAlign: "center", fontSize: "2vw"}}>
                Could not load payments
            </div>
        </div>);
    }

    return (
        <div style={{display: "flex", flexDirection: "column", alignItems: "flex-start", rowGap: "calc(1vw + 10px)"}}>
            <div style={{...smTitleStyle}}>Payment portal</div>
            <div>
                <DatePicker value={dayFilter}
                            maxDate={startOfDay(addHours(new Date(), -12))}
                            onChange={(d) => updateDayFilter(d)}/>
            </div>
            <PaymentOrderForm style={{color: colors.text2, backgroundColor: colors.bg2, padding: "calc(0.5vw + 5px)"}}
                              type={"Create"}
                              groupId={parseInt(groupId)}
                              onSubmit={(values) => {
                                  if (!isToday) {
                                      values.day = dayFilter.toISOString();
                                  }
                                  return createPaymentOrderPost({createPaymentOrderModel: values});
                              }}/>
            <div style={{
                display: "grid",
                gridTemplateRows: " auto auto auto",
                gridTemplateColumns: "auto auto",
                gridGap: "calc(1vw + 10px)"
            }}>
                <PaymentOrderTable style={{gridRow: "1 / 2", gridColumn: "1 / 3"}} 
                                   onDelete={(paymentId) => deletePaymentOrderPost({ deletePaymentOrderModel: { paymentId: paymentId } })}
                                   paymentOrders={dayPaymentOrders}/>
                <PaymentTypeTotalTable style={{gridRow: "2 / 3", gridColumn: "1 / 2"}}
                                       paymentOrders={dayPaymentOrders}/>
                <LadiesPayTable style={{gridRow: "2 / 4", gridColumn: "2 / 3"}}
                                paymentOrders={dayPaymentOrders}
                                ladyPays={dayLadyPays}
                                onDelete={(ladyPayId) => deleteLadyPayPost({deleteLadyPayModel: {ladyPayId: ladyPayId}})}
                                onCreate={(ladyId, amount) => createLadyPayPost({
                                    createLadyPayModel: {
                                        ladyId: ladyId,
                                        amount: amount,
                                        dayDate: dayFilter.toISOString()
                                    }
                                })}
                                bonusAmounts={[{quantity: 5, amount: 100},
                                    {quantity: 10, amount: 200},
                                    {quantity: 20, amount: 500},
                                    {quantity: 40, amount: 700},
                                    {quantity: 60, amount: 1000}]} />
            </div>


        </div>);
}