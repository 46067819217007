import React from "react";
import {useAppDispatch, useAppSelector} from "../app/hooks";
import {currentColors} from "../slices/style";
import {useParams} from "react-router-dom";
import {ResetPasswordForm} from "./ResetPasswordForm";
import {useResetPasswordMutation} from "../api/psApi";
import {push} from "redux-first-history";

export function ResetPassword({}: {}){
    const colors = useAppSelector(currentColors);
    const { token } = useParams();
    const [ resetPasswordPost, resetPasswordState ] = useResetPasswordMutation();
    const dispatch = useAppDispatch();

    return (
        <div style={{display: "flex", flexDirection: "row", alignItems: "center", height: "100%"}}>
            <div style={{display: "flex", flexDirection: "column", alignItems: "center", width: "100%"}}>
                <ResetPasswordForm style={{width: "calc(20vw + 200px)" }}
                                   defaultValues={{ token: token }}
                                   onSubmit={(values) => resetPasswordPost({ resetPasswordModel: values })
                                       .then((value: any) => {
                                           if(!value.error){
                                               dispatch(push("/login"))
                                           }
                                           return value;
                                       })} />
            </div>
        </div>
    );
}