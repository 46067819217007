import React from "react";
import {useAppSelector} from "../app/hooks";
import {currentColors} from "../slices/style";
import {useGetCurrentOrderQuery, useListLadyByStreamEventIdQuery, ViewStreamEventModel} from "../api/psApi";
import {Loader} from "./Loader";
import {StreamEventCurrentOrder} from "./StreamEventCurrentOrder";
import {CreateOrder} from "./CreateOrder";

export function StreamEventOrder({ streamEvent, style  }: { streamEvent: ViewStreamEventModel, style?: React.CSSProperties }) {
    const colors = useAppSelector(currentColors);
    const {
        data: ladies,
        isLoading: ladiesIsLoading,
        isFetching: ladiesIsFetching
    } = useListLadyByStreamEventIdQuery({ streamEventId: streamEvent.streamEventId, current: true });
    const {
        data: currentOrder,
        isLoading: currentOrderIsLoading,
        isFetching: currentOrderIsFetching
    } = useGetCurrentOrderQuery();
    
    if(ladiesIsLoading 
        || currentOrderIsLoading){
        return (
            <Loader height={"10vw"} width={"10vw"} />
        );
    }

    return (<div style={{ display: "flex", flexDirection: "row", alignItems: "center", height: "100%" }}>
        <div style={{ width: "100%", textAlign: "center", fontSize: "calc(1vw + 10px)", padding: "calc(0.5vw + 5px)", boxSizing: "border-box" }}>
            Coming soon... <br/> Get the links <br/> under the stream
        </div>
    </div>);
    
    if(currentOrder) {
        return <div style={{ ...style }}>
            <StreamEventCurrentOrder style={style} streamEvent={streamEvent} order={currentOrder} />
        </div>
    }
    
    return (
        <div style={{ ...style }}>
            <CreateOrder ladies={ladies} streamEvent={streamEvent} style={style} />
        </div>
    );
}