import React from "react";
import {useAppDispatch, useAppSelector} from "../app/hooks";
import {currentColors} from "../slices/style";
import { useGetStreamEventByGroupIdQuery } from "../api/psApi";
import {Loader} from "./Loader";
import {GroupCreateLiveStream} from "./GroupCreateLiveStream";
import {GroupStartLiveStream} from "./GroupStartLiveStream";
import {GroupCurrentLiveStream} from "./GroupCurrentStreamEvent";

const streamInfoStyle: React.CSSProperties = {  };
export function GroupLiveStream({ style, groupId }: { style?: React.CSSProperties, groupId: number }) {
    const colors = useAppSelector(currentColors);
    const dispatch = useAppDispatch();

    const {
        data: streamEvent,
        isLoading: streamEventIsLoading,
        isFetching: streamEventIsFetching
    } = useGetStreamEventByGroupIdQuery({groupId: groupId});

    if (streamEventIsLoading) {
        return (
            <Loader height={"10vw"} width={"10vw"} />
        );
    }

    if (streamEvent) {
        if(!streamEvent.startedDate) {
            return (
                <GroupStartLiveStream streamEvent={streamEvent} />  
            );
        }
        
        return (
            <GroupCurrentLiveStream streamEvent={streamEvent} />
        );
    }

   return (
       <GroupCreateLiveStream groupId={groupId} />
   );
}