import React, {useState} from "react";
import {ResetPasswordModel} from "../api/psApi";
import {useAppSelector} from "../app/hooks";
import {currentColors} from "../slices/style";
import {useForm} from "react-hook-form";
import {buttonStyle, errorStyle, inputStyle, labelStyle} from "../styles/baseStyles";
import {FormField} from "./FormField";
import {validatePassword} from "./RegisterForm";

export function ResetPasswordForm({style, onSubmit, defaultValues}: {
    style?: React.CSSProperties;
    onSubmit: (values : ResetPasswordModel) => Promise<any>;
    defaultValues: ResetPasswordModel & { passwordConf?: string  };
}){
    const colors = useAppSelector(currentColors);
    const {register, handleSubmit, formState, watch, resetField, control, reset} = useForm({
        mode: "onChange",
        criteriaMode: "all",
        defaultValues: defaultValues
    });
    const [errorMsg, updateErrorMsg] = useState(false);

    const formOnSubmit = (values: ResetPasswordModel & { passwordConf?: string }): Promise<any> => {
        delete values.passwordConf;
        return onSubmit(values).then(value => {
            if(value.error) {
                updateErrorMsg(value.error?.data || "Password reset failed");
            } else{
                reset({});
            }
            return value;
        });
    }
    
    const password = watch("password");
    return (
        <form onSubmit={handleSubmit(formOnSubmit)}
              style={{
                  display: "grid",
                  gridTemplateRows: "auto auto auto",
                  gridTemplateColumns: "auto 1fr auto",
                  gridRowGap: "calc(0.5vw + 5px)",
                  gridColumnGap: "calc(0.6vw + 6px)",
                  ...style
              }}>
            <input type={"hidden"} {...register("token", {})} />
            
            <div style={{gridRow: "1 / 2", gridColumn: "1 / 2", ...labelStyle}}>New password:</div>
            <FormField style={{gridRow: "1 / 2", gridColumn: "2 / 4"}} errors={formState.errors}
                       name={"password"}>
                <input type={"password"}
                       style={{ ...inputStyle }}
                       {...register("password", {
                           required: "This field is required",
                           validate: value => !value || validatePassword(value) ? undefined : "Password too weak"
                       })} />
            </FormField>

            <div style={{gridRow: "2 / 3", gridColumn: "1 / 2", ...labelStyle}}>Password confirmation:</div>
            <FormField style={{gridRow: "2 / 3", gridColumn: "2 / 4"}} errors={formState.errors}
                       name={"passwordConf"}>
                <input type={"password"}
                       style={{ ...inputStyle }}
                       {...register("passwordConf", {
                           required: "This field is required",
                           validate: value => value === password ? undefined : "The passwords do not match"
                       })} />
            </FormField>

            <div style={{
                ...errorStyle,
                gridRow: "3 / 4",
                gridColumn: "1 / 3",
                textAlign: "center"
            }}>{errorMsg}</div>
            <input style={{ gridRow: "3 / 4", gridColumn: "3 / 4", ...buttonStyle }}
                   type="submit"
                   value="Reset password"
                   disabled={!formState.isValid} />
        </form>
    );
}