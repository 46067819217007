import React from "react";
import {useAppSelector} from "../app/hooks";
import {currentColors} from "../slices/style";
import { GroupView } from "./GroupView";
import {pageStyle} from "../styles/baseStyles";
import {useParams} from "react-router-dom";

export function GroupViewPage() {
    const colors = useAppSelector(currentColors);
    const { groupId } = useParams();

    return (
        <div style={{ ...pageStyle, height: "100%" }}>
            <GroupView groupId={parseInt(groupId) || 1} />
        </div>
    );
}