import {linkStyle, titleStyle} from "../styles/baseStyles";
import {VideoPlayer} from "./VideoPlayer";
import {StreamChat} from "./StreamChat";
import React, {useState} from "react";
import {useAppSelector} from "../app/hooks";
import {currentColors} from "../slices/style";
import {
    useListLadyByStreamEventIdQuery,
    useListStreamLinkByStreamEventIdQuery,
    ViewStreamEventModel
} from "../api/psApi";
import {Lady} from "./Lady";
import Linkify from 'linkify-react';
import {ReactComponent as HeartIcon} from "../icons/heartIcon.svg";
import {StreamEventOrder} from "./StreamEventOrder";
import {Loader} from "./Loader";
import {StreamLinkView} from "./StreamLinkView";
import {useMediaQuery, } from "react-responsive";

export default function StreamEventView ({ stream, withChat, withOrder }: { stream: ViewStreamEventModel, withChat: boolean, withOrder: boolean  }) {
    const colors = useAppSelector(currentColors);
    const {
        data: ladies,
        isLoading: ladiesIsLoading,
        isFetching: ladiesIsFetching
    } = useListLadyByStreamEventIdQuery({ streamEventId: stream.streamEventId, current: true });
    const {
        data: streamLinks,
        isLoading: streamLinksLoading,
        isFetching: streamLinksFetching
    } = useListStreamLinkByStreamEventIdQuery({ streamEventId: stream.streamEventId });
    const [ orderIsOpened, updateOrderIsOpened ] = useState(!withChat);

    const isMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    
    const selectedTabStyle: React.CSSProperties= { backgroundColor: colors.bg2, color: colors.text2 }
    const tabStyle: React.CSSProperties = {
        ...linkStyle,
        padding: "0.5vw 1vw",
        fontSize: "calc(0.6vw + 6px)",
        color: colors.text1,
        backgroundColor: colors.bg1,
        borderLeft: `1px solid ${colors.bg1}`,
        borderRight: `1px solid ${colors.bg1}`,
        borderTop: `1px solid ${colors.bg1}`
    }
    
    const tabContentStyle: React.CSSProperties = {
        borderLeft: `1px solid ${colors.bg1}`,
        borderRight: `1px solid ${colors.bg1}`,
        borderBottom: `1px solid ${colors.bg1}`,
        color: colors.text2,
        backgroundColor: colors.bg2,
        borderBottomLeftRadius: "10px",
        borderBottomRightRadius: "10px",
        boxSizing: "border-box"
    }
    
    return (
        <div style={{
            minHeight: "50vw",
            display: "grid",
            gridTemplateRows: "auto auto auto auto auto",
            gridTemplateColumns: withChat || withOrder ? "4fr auto" : "1fr",
            columnGap: isMobile ? "0px" : "calc(0.6vw + 6px)",
            rowGap: "calc(0.4vw + 4px)",
            padding: isMobile ? "0px" : "0px calc(1.5vw + 15px)"
        }}>
            {stream.type !== "Offsite"
            && <div style={{ gridRow: "1 / 3", gridColumn: "1 / 2", ...(isMobile && !isPortrait && { minHeight: "30vw" }) }}>
                <VideoPlayer style={{  }}
                     playerProps={{
                        url: stream.liveEndpointHls,
                        controls: true,
                         width: "100%",
                         height: "100%",
                         muted: true,
                         playing: true
                    }} />
            </div>}
            <div style={{ gridRow: stream.type !== "Offsite" ? "3 / 4" : "1 / 2", gridColumn: "1 / 2" }}>
                <div style={{...titleStyle, fontSize: "calc(1vw + 10px)" }}>{stream.title}</div>
            </div>
            {(withChat || withOrder) 
            && <div style={{
                gridRow: isMobile && isPortrait 
                    ? (stream.type !== "Offsite" ? "4 / 5" : "5 / 6") 
                    : "1 / 3", 
                gridColumn: isMobile && isPortrait 
                    ? "1 / 3" 
                    : "2 / 3", 
                display: "grid",
                gridTemplateRows: "auto 1fr",
                gridTemplateColumns: "1fr 1fr",
                minHeight: "calc(15vw + 150px)"
            }}>
                <div style={{ 
                    gridRow: "1 / 2", 
                    gridColumn: "1 / 2",
                    borderTopLeftRadius: "10px",
                    ...tabStyle,
                    ...(orderIsOpened
                        ? {}
                        : selectedTabStyle)
                }}
                     onClick={(e) => updateOrderIsOpened(false)}>
                    Chat on stream
                </div>
                <div style={{ 
                    gridRow: "1 / 2", 
                    gridColumn: "2 / 3",
                    borderTopRightRadius: "10px",
                    ...tabStyle,
                    ...(orderIsOpened
                        ? selectedTabStyle
                        : {})
                }}
                onClick={(e) => updateOrderIsOpened(true)}>
                    Support the ladies
                </div>
                <div style={{ 
                    gridRow: "2 / 3", 
                    gridColumn: "1 / 3", 
                    visibility: orderIsOpened ? "hidden" : "visible",
                    ...tabContentStyle
                }}>
                    <StreamChat style={{
                        height: "100%", 
                        backgroundColor: colors.bg2, 
                        color: colors.text2, 
                        fontSize: "calc(0.6vw + 6px)" }} streamEventId={stream.streamEventId}/>
                </div>
                <div style={{ 
                    gridRow: "2 / 3", 
                    gridColumn: "1 / 3", 
                    visibility: orderIsOpened ? "visible" : "hidden",
                    ...tabContentStyle
                }}>
                    <StreamEventOrder streamEvent={stream} style={{height: "100%", backgroundColor: colors.bg2, color: colors.text2 }} />
                </div>
            </div>}
            <div style={{ 
                gridRow: stream.type !== "Offsite" 
                    ? "5 / 6" 
                    : (isMobile && isPortrait ? "2 / 5" : "2 / 6"), 
                gridColumn: "1 / 2", 
                marginRight: "calc(0.8vw + 8px)",
                padding: "calc(0.4vw + 4px) 0",
            }}>
                <div style={{ display: "flex", flexDirection: stream.type !== "Offsite" ? "row" : "row-reverse" }}>
                    <Linkify options={{ attributes: { target: '_blank', style: linkStyle } }}>
                        <div style={{ whiteSpace: "pre-wrap", flex: "0.5", fontSize: "calc(0.7vw + 7px)" }}>
                            {stream.description}
                        </div>
                    </Linkify>
                    <div style={{ 
                        flex: "0.5", 
                        display: "flex", 
                        flexDirection: "column", 
                        alignItems: "center",
                        rowGap: "calc(0.4vw + 4px)"
                    }}>
                        {withChat && <div style={{ ...linkStyle,
                            fontSize: "calc(0.8vw + 8px)",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            columnGap: "calc(0.2vw + 2px)",
                        }}>
                            <HeartIcon height={"1em"} style={{ color: "#ff0000" }} />
                            <div onClick={(e) => updateOrderIsOpened(true)}>
                                Support the ladies
                            </div>
                            <HeartIcon height={"1em"} style={{ color: "#ff0000" }} />
                        </div>}
                        <div style={{  display: "flex", flexDirection: "row", columnGap: "calc(0.4vw + 4px)" }}>
                            {ladies
                            && ladies.map(lady => <Lady key={lady.ladyId} style={{}} imgStyle={{ height: "calc(3.5vw + 35px)" }} lady={lady} />)}
                        </div>
                    </div>
                </div>
                {stream.type !== "OnSite" 
                    && <div style={{ paddingTop: "calc(1vw + 10px)" }}>
                    {streamLinksLoading && <Loader height={"100px"} width={"100px"} />}
                    {streamLinks && streamLinks.map(link => 
                        <StreamLinkView key={link.streamLinkId} 
                                        streamLink={link} />)}
                </div>}
            </div>
        </div>
    );
}