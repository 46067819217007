import React from "react";
import {
    CreateLadyDrinkModel,
    CreatePaymentOrderModel,
    useListCurrencyQuery, useListLadyByGroupIdQuery,
    useListPaymentTypeByGroupIdQuery
} from "../api/psApi";
import {useAppSelector} from "../app/hooks";
import {currentColors} from "../slices/style";
import {Controller, useForm} from "react-hook-form";
import {buttonStyle, inputStyle, labelStyle, linkStyle, smButtonStyle, smInputStyle} from "../styles/baseStyles";
import {FormField} from "./FormField";
import {ControlSelect} from "./ControlSelect";
import {Loader} from "./Loader";
import * as _ from "lodash";
import {DatePicker} from "./DatePicker";

type FormType = "Create" | "Update";
const formLabelStyle: React.CSSProperties = { ...labelStyle, textAlign: "center" };
export function PaymentOrderForm({ type, style, defaultValues, onSubmit, groupId }: {
    type: FormType;
    style?: React.CSSProperties;
    defaultValues?: CreatePaymentOrderModel;
    groupId: number;
    onSubmit: (values: CreatePaymentOrderModel) => Promise<any>;
}) {
    const colors = useAppSelector(currentColors);
    const {register, handleSubmit, formState, watch, resetField, control, reset} = useForm({
        mode: "onChange",
        criteriaMode: "all",
        defaultValues: defaultValues
    });
    const {
        data: paymentTypes,
        isLoading: paymentTypesIsLoading,
        isFetching: paymentTypesIsFetching
    } = useListPaymentTypeByGroupIdQuery({groupId: groupId});
    const {data: currencies, isLoading: currenciesIsLoading, isFetching: currenciesIsFetching} = useListCurrencyQuery();
    const {
        data: ladies,
        isLoading: ladiesIsLoading,
        isFetching: ladiesIsFetching
    } = useListLadyByGroupIdQuery({groupId: groupId});

    if (paymentTypesIsLoading || currenciesIsLoading || ladiesIsLoading) {
        return (
            <Loader height={"200px"} width={"200px"}/>
        );
    }

    const formOnSubmit = (values: CreatePaymentOrderModel) => {
        values.groupId = groupId;
        onSubmit(values).then(d => {
            if(d.data) {
                // reset();
            }
        });
    }
    
    // const ladyDrinks = watch("ladyDrinks");
    return (
        <form onSubmit={handleSubmit(formOnSubmit)} style={{
            display: "grid",
            gridTemplateRows: "auto auto",
            gridTemplateColumns: "auto auto auto auto auto auto",
            columnGap: 15,
            rowGap: 15,
            ...style
        }}>
            {/*<div style={{gridRow: "1 / 2", gridColumn: "1 / 2", ...formLabelStyle, color: colors.text1}}>Date</div>*/}
            {/*<FormField style={{gridRow: "2 / 3", gridColumn: "1 / 2", minWidth: "calc(4vw + 40px)" }} errors={formState.errors} name={"da"}>*/}
            {/*    <Controller*/}
            {/*        render={({field}) =><DatePicker value={field.value ? new Date(field.value) : undefined} onChange={(val) => field.onChange(val.toISOString())} />}*/}
            {/*        name={"day"}*/}
            {/*        control={control} />*/}
            {/*</FormField>*/}
            
            <div style={{gridRow: "1 / 2", gridColumn: "2 / 3", ...formLabelStyle, color: colors.text1}}>Type</div>
            <FormField style={{gridRow: "2 / 3", gridColumn: "2 / 3", minWidth: "calc(4vw + 40px)" }} errors={formState.errors} name={"paymentTypeId"}>
                <ControlSelect options={[{label: "------", value: null}].concat(paymentTypes.map(x => ({
                    label: x.label,
                    value: x.paymentTypeId
                })))} name={"paymentTypeId"} control={control}/>
            </FormField>

            <div style={{gridRow: "1 / 2", gridColumn: "3 / 4", ...formLabelStyle, color: colors.text1}}>Amount</div>
            <FormField style={{gridRow: "2 / 3", gridColumn: "3 / 4"}} errors={formState.errors} name={"amount"}>
                <Controller
                    render={({field}) =><input 
                        type={"number"} 
                        step={0.01} 
                        value={(field.value || 0) / 100}
                        onChange={(e) => field.onChange(parseFloat(e.target.value) * 100)}
                        style={{...smInputStyle, width: 80 }} />}
                    name={"amount"}
                    control={control} />
            </FormField>

            <div style={{gridRow: "1 / 2", gridColumn: "4 / 5", ...formLabelStyle, color: colors.text1}}>Currency</div>
            <FormField style={{gridRow: "2 / 3", gridColumn: "4 / 5", minWidth: "calc(4vw + 40px)" }} errors={formState.errors} name={"currencyId"}>
                <ControlSelect options={[{label: "------", value: null}].concat(currencies.map(x => ({
                    label: x.code,
                    value: x.currencyId
                })))} name={"currencyId"} control={control}/>
            </FormField>

            <div style={{gridRow: "1 / 2", gridColumn: "5 / 6", ...formLabelStyle, color: colors.text1}}>Drinks</div>
            <FormField style={{gridRow: "2 / 3", gridColumn: "5 / 6"}} errors={formState.errors} name={"ladyDrinks"}>
                <Controller
                    render={({field}) => <div style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        columnGap: "calc(0.5vw + 5px)"
                    }}>
                        {ladies.map(lady => {
                            let fieldLadyDrinks = (field.value || []) as Array<CreateLadyDrinkModel>
                            let drinks = _.sum(fieldLadyDrinks.filter(x => x.ladyId === lady.ladyId).map(x => x.quantity));
                            return (
                                <div key={lady.ladyId} style={{ display: "flex", flexDirection: "row", alignItems: "center", columnGap: "4px" }}>
                                    <div>#{lady.number} {lady.name}</div>
                                    <input type={"number"} style={{ ...smInputStyle, width: 50 }} value={drinks} onChange={(e) => {
                                        if(e.target.valueAsNumber || e.target.valueAsNumber === 0){
                                            if(e.target.valueAsNumber === 0) {
                                                field.onChange(fieldLadyDrinks.filter(x => x.ladyId !== lady.ladyId))
                                            } else if (0 < e.target.valueAsNumber){
                                                let exist = fieldLadyDrinks.some(x => x.ladyId === lady.ladyId);
                                                if(exist){
                                                    field.onChange(fieldLadyDrinks.map(x => x.ladyId === lady.ladyId 
                                                        ? { ...x, quantity: e.target.valueAsNumber } 
                                                        : x));

                                                } else {
                                                    field.onChange(fieldLadyDrinks.concat([{ ladyId: lady.ladyId, quantity: e.target.valueAsNumber }]));
                                                }
                                            }
                                        }
                                    }} />
                                </div>
                            );
                        })}
                    </div>}
                    name="ladyDrinks"
                    control={control}/>
            </FormField>

            <button style={{...smButtonStyle, gridRow: "2 / 3", gridColumn: "6 / 7"}}
                    type="submit"
                    disabled={!formState.isValid}>{type === "Create" ? "Add" : "Update"}</button>
        </form>
    );
}