import {buttonStyle, smInputStyle, smLabelStyle, linkStyle} from "../styles/baseStyles";
import {FormField} from "./FormField";
import React, {useRef, useState} from "react";
import {useForm} from "react-hook-form";
import {CreateLadyModel, UpdateLadyModel} from "../api/psApi";

type FormType = (CreateLadyModel | UpdateLadyModel) & { imageInput?: Array<File> };
type LadyFormType = "Create" | "Update";

export function LadyForm({ type, onRequestClose, defaultValues, onSubmit  }
                             : {
    type: LadyFormType;
    onRequestClose?: () => void; 
    defaultValues?: FormType;
    onSubmit: (values: CreateLadyModel | UpdateLadyModel) => void;
}) {
    const imgFileRef = useRef(null);
    const [hasImg, updateHasImg] = useState(false);
    const {
        register: register
        , handleSubmit: handleSubmit
        , formState: formState
        , watch: watch
        , reset: reset
        , resetField: resetField
    } = useForm({
        mode: "onChange",
        criteriaMode: "all",
        defaultValues: defaultValues
    });
    
    const handleOnSubmit = (data: FormType) => {
        if(data.imageInput && data.imageInput[0]){
            let reader = new FileReader();
            reader.readAsDataURL(data.imageInput[0]);
            reader.onload = function () {
                data.imageBase64 = (reader.result as string).split(",")[1];
                data.imageFilename = data.imageInput[0].name;
                delete data.imageInput;
                onSubmit(data);
            };
        } else {
            delete data.imageInput;
            onSubmit(data);
        }
    };

    const imageInput = watch("imageInput");
    const imageRegister = register("imageInput");

    return (
        <form onSubmit={handleSubmit(handleOnSubmit)}
              style={{
                  display: "grid",
                  gridTemplateRows: "auto auto auto auto",
                  gridTemplateColumns: "auto 1fr auto",
                  gridRowGap: "calc(0.3vw + 3px)",
                  gridColumnGap: "(0.5vw + 5px)"
              }}>
            <div style={{gridRow: "1 / 2", gridColumn: "1 / 2", ...smLabelStyle}}>Name:</div>
            <FormField style={{gridRow: "1 / 2", gridColumn: "2 / 4"}} errors={formState.errors}
                       name={"name"}>
                <input type={"text"} style={{ ...smInputStyle }} {...register("name", {required: "This field is required"})} />
            </FormField>
            <div style={{gridRow: "2 / 3", gridColumn: "1 / 2", ...smLabelStyle}}>Number:</div>
            <FormField style={{gridRow: "2 / 3", gridColumn: "2 / 4"}} errors={formState.errors}
                       name={"number"}>
                <input type={"number"} style={{ ...smInputStyle }} {...register("number", {
                    required: "This field is required",
                    valueAsNumber: true
                })} />
            </FormField>
            <div style={{gridRow: "3 / 4", gridColumn: "1 / 2", ...smLabelStyle}}>Picture:</div>
            <FormField style={{gridRow: "3 / 4", gridColumn: "2 / 4"}} errors={formState.errors}
                       name={"imageInput"}>
                <div>
                    {type === "Update" 
                        && (!imageInput || imageInput.length === 0)  
                        && <div style={{ fontSize: "calc(0.4vw + 4px)", fontStyle: "italic" }}>leave empty to not update</div>}
                    <input ref={imgFileRef}
                           style={{visibility: imageInput && imageInput.length !== 0 ? "collapse" : "visible"}}
                           type={"file"}
                           {...imageRegister}/>
                    {imageInput
                    && imageInput.length !== 0
                    &&
                    <div style={{display: "flex", flexDirection: "row", alignItems: "flex-start", columnGap: "0.2vw"}}>
                        <img src={URL.createObjectURL(imageInput[0])}
                             alt="preview (no processing)"
                             style={{maxWidth: 128, maxHeight: 128}}
                        />
                        <div style={{...linkStyle, fontSize: "1vw"}}
                             onClick={(e) => {
                                 resetField("imageInput");
                             }}>X
                        </div>
                    </div>}
                </div>
            </FormField>
            <div style={{
                gridRow: "4 / 5",
                gridColumn: "3 / 4",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                columnGap: "0.5vw"
            }}>
                <button style={{ ...buttonStyle }} onClick={(e) => onRequestClose()} type="button">Close</button>
                <button style={{ ...buttonStyle }} type="submit" disabled={!formState.isValid}>{type === "Create" ? "Add" : "Update"}</button>
            </div>
        </form>
    );
}