import {FormField} from "./FormField";
import {buttonStyle, inputStyle, textareaStyle} from "../styles/baseStyles";
import React from "react";
import {useForm, Controller } from "react-hook-form";
import {
    StreamEventModel,
    UpdateStreamEventModel,
    useListLadyByGroupIdQuery,
    useListStreamLinkByGroupIdQuery
} from "../api/psApi";
import {Loader} from "./Loader";
import {Lady} from "./Lady";
import {GroupStreamLinkView} from "./GroupStreamLinkView";
import {StreamLinkView} from "./StreamLinkView";


export function UpdateStreamEventForm({streamEvent, currentLadiesId, currentStreamLinkIds, onSubmit, style, onRequestClose, submitText }:{
    streamEvent: StreamEventModel;
    currentLadiesId: Array<number>;
    currentStreamLinkIds: Array<number>;
    style?: React.CSSProperties; 
    onSubmit: (values: UpdateStreamEventModel) => void;
    onRequestClose?: () => void;
    submitText: string;
}) {
    const {
        register: startRegister, 
        formState: startFormState, 
        watch: startWatch,
        control: startControl,
        handleSubmit: startHandleSubmit,
        
    } = useForm({
        mode: "onChange",
        criteriaMode: "all",
        defaultValues: streamEvent 
            ? { 
                streamEventId: streamEvent.streamEventId,
                title: streamEvent.title,
                description: streamEvent.description,
                ladiesId: currentLadiesId,
                streamLinksId: currentStreamLinkIds
        } as UpdateStreamEventModel 
            : {}
    });
    
    const {
        data: ladies,
        isLoading: ladiesLoading,
        isFetching: ladiesIsFetching
    } = useListLadyByGroupIdQuery({groupId: streamEvent.groupId});

    const {
        data: streamLinks,
        isLoading: streamLinksLoading,
        isFetching: streamLinksFetching
    } = useListStreamLinkByGroupIdQuery({groupId: streamEvent.groupId});
    
    // const ladiesId = startWatch("ladiesId");
    return (
        <form onSubmit={startHandleSubmit(onSubmit)} style={{
            display: "grid",
            gridTemplateRows: "auto auto auto auto auto",
            gridTemplateColumns: "auto 1fr auto",
            gridGap: "1vw",
            ...style
        }}>
            <div style={{gridRow: "1 / 2", gridColumn: "1 / 2"}}>Title:</div>
            <FormField style={{gridRow: "1 / 2", gridColumn: "2 / 4" }} errors={startFormState.errors} name={"title"}>
                <input type={"text"}
                       style={{...inputStyle}} {...startRegister("title", {required: "Title is required"})} />
            </FormField>
            <div style={{gridRow: "2 / 3", gridColumn: "1 / 2"}}>Description:</div>
            <FormField style={{gridRow: "2 / 3", gridColumn: "2 / 4" }} errors={startFormState.errors}
                       name={"description"}>
                        <textarea
                            style={{
                                ...inputStyle, 
                                ...textareaStyle,
                                height: "4em"
                            }} {...startRegister("description", {required: "A description is required"})}>
                        </textarea>
            </FormField>
            <div style={{gridRow: "3 / 4", gridColumn: "1 / 2"}}>Ladies working:</div>
            <FormField style={{gridRow: "3 / 4", gridColumn: "2 / 4"}} errors={startFormState.errors}
                       name={"ladiesId"}>
                <Controller
                    render={({ field }) => <div style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        columnGap: "0.5vw"
                    }}>
                        {ladiesLoading && <Loader height={"1vw"} width={"1vw"}/>}
                        {ladies
                        && ladies.map(lady => {
                            let ladiesId = field.value || [];
                            let isSelected = ladiesId
                                && ladiesId.some(x => x === lady.ladyId);
                            return <Lady key={lady.ladyId}
                                         lady={lady}
                                         style={{
                                             cursor: "pointer",
                                             ...(isSelected
                                                 ? {
                                                     boxSizing: "border-box"
                                                     , WebkitBoxSizing: "border-box"
                                                     , MozBoxSizing: "border-box"
                                                     , outline: "0.2vw solid #2d69bd"
                                                 }
                                                 : {  })
                                         }}
                                         onClick={() => {
                                             if (isSelected) {
                                                 field.onChange(ladiesId.filter(x => x !== lady.ladyId));
                                             } else {
                                                 field.onChange((ladiesId || []).concat(lady.ladyId));
                                             }
                                         }}
                                         imgStyle={{height: "4vw"}}/>;
                        })}
                    </div>}
                    name="ladiesId"
                    control={startControl}
                    
                />
            </FormField>
            {streamEvent.type !== "OnSite" 
            && <React.Fragment>
                <div style={{gridRow: "4 / 5", gridColumn: "1 / 2"}}>Links to show:</div>
                <FormField style={{gridRow: "4 / 5", gridColumn: "2 / 4"}} errors={startFormState.errors}
                           name={"streamLinksId"}>
                    <Controller
                        render={({ field }) => <div style={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            columnGap: "0.5vw"
                        }}>
                            {ladiesLoading && <Loader height={"1vw"} width={"1vw"}/>}
                            {streamLinks
                            && streamLinks.map(link => {
                                let ids = field.value || [];
                                let isSelected = ids
                                    && ids.some(x => x === link.streamLinkId);
                                return <StreamLinkView key={link.streamLinkId}
                                                       style={{
                                                           cursor: "pointer",
                                                           ...(isSelected
                                                               ? {
                                                                   boxSizing: "border-box"
                                                                   , WebkitBoxSizing: "border-box"
                                                                   , MozBoxSizing: "border-box"
                                                                   , outline: "0.2vw solid #2d69bd"
                                                               }
                                                               : {  })
                                                       }}
                                                       onClick={(e) => {
                                                           if (isSelected) {
                                                               field.onChange(ids.filter(x => x !== link.streamLinkId));
                                                           } else {
                                                               field.onChange((ids || []).concat(link.streamLinkId));
                                                           }
                                                           e.preventDefault();
                                                           e.stopPropagation();
                                                       }}
                                                       streamLink={link} />;
                            })}
                        </div>}
                        name="streamLinksId"
                        control={startControl}

                    />
                </FormField>
            </React.Fragment>}
            <div style={{
                gridRow: "5 / 6",
                gridColumn: "3 / 4",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                columnGap: "1vw"
            }}>
                {onRequestClose
                && <button
                    onClick={(e) => onRequestClose()}
                    style={{
                        ...buttonStyle,
                        fontSize: "2vw"
                    }}
                    type={"button"}>Close</button>}
                <button
                    style={{
                        ...buttonStyle,
                        fontSize: "2vw"
                    }}
                    type={"submit"}>{submitText}</button>
            </div>

        </form>
    );
}