import React from "react";
import {Table} from "./Table";
import {PaymentOrderModel} from "../api/psApi";
import { format } from "date-fns";
import * as _ from "lodash";
import {ReactComponent as DeleteIcon} from "../icons/deleteIcon.svg";
import {linkStyle} from "../styles/baseStyles";

export function PaymentOrderTable({ style, paymentOrders, onDelete } : { 
    style?: React.CSSProperties;
    paymentOrders: Array<PaymentOrderModel>;
    onDelete: (paymentId) => Promise<any>;
}) {
    const ladies = _.sortBy(_.uniqBy(_.flatten(paymentOrders.map(x => x.ladyDrinks)).map(x => x.lady), x => x.ladyId), x => x.number);
    return (
        <Table<PaymentOrderModel> style={{ ...style }} columns={[
            {
                Header: <div style={{ textAlign: "center" }}>Payment</div>,
                id: "payment",
                columns: [
                    {
                        Header: 'Id',
                        accessor: 'paymentId',
                    },
                    {
                        Header: 'Date',
                        accessor: 'creationDate',
                        Cell: ({value}) => format(new Date(value), "dd/MM/yy HH:mm:ss")
                    },
                    {
                        Header: 'Type',
                        accessor: 'paymentTypLabel',
                    },
                    {
                        Header: 'Currency',
                        accessor: 'currencyCode',
                    },
                    {
                        Header: 'Amount',
                        accessor: 'amount',
                        Cell: ({value}) => ((value || 0) / 100).toFixed(2)
                    },
                    {
                        Header: 'Amount THB',
                        accessor: d => d.amount * d.thbValue,
                        Cell: ({value}) => ((value || 0) / 100).toFixed(2),
                        Footer: info => {
                            const total = _.sumBy(info.rows, x => x.original.amount * x.original.thbValue);
                            return <div style={{ fontWeight: "bold" }}>{((total || 0) / 100).toFixed(2)}</div>;
                        }
                    },
                    {
                        Header: 'Fees',
                        accessor: 'fees',
                    }
                ]
            },
            {
                Header: <div style={{ textAlign: "center" }}>Drinks</div>,
                id: "drinks",
                columns: ladies.map(lady => ({
                    Header: `#${lady.number} ${lady.name}`,
                    accessor: d => _.sumBy(d.ladyDrinks.filter(x => x.lady.ladyId === lady.ladyId), x => x.quantity),
                    Cell: ({value}) => <div style={{ textAlign: "center" }}>{value}</div>,
                    Footer: info => {
                        const total = _.sumBy(info.rows, x => _.sumBy(x.original.ladyDrinks.filter(x => x.lady.ladyId === lady.ladyId), y => y.quantity));
                        return <div style={{ textAlign: "center", fontWeight: "bold" }}>{total}</div>;
                    }
                }))
            },
            {
                Header: '',
                id: "actions",
                accessor: d => d.paymentId,
                Cell: ({value}) => <DeleteIcon style={{
                    ...linkStyle,
                    width: "calc(0.5vw + 5px)",
                    height: "calc(0.5vw + 5px)"
                }} onClick={(e) => onDelete(value)} />,
            },
        ]
        } data={paymentOrders}/>
    );
}