import React from "react";
import {useAppDispatch, useAppSelector} from "../app/hooks";
import {currentColors} from "../slices/style";
import { useListStreamLinkByGroupIdQuery } from "../api/psApi";
import {Hearts} from "react-loader-spinner";
import {ReactComponent as FacebookIcon} from "../icons/facebookIcon.svg";
import {ReactComponent as YoutubeIcon} from "../icons/youtubeIcon.svg";
import {linkStyle} from "../styles/baseStyles";
import {ReactComponent as MainIcon} from "../icons/mainIcon.svg";
import {Link} from "react-router-dom";
import {Loader} from "./Loader";
import {StreamLinkView} from "./StreamLinkView";


export function GroupStreamLinkView({ style, groupId }: { style?: React.CSSProperties; groupId: number }) {
    const colors = useAppSelector(currentColors);
    const dispatch = useAppDispatch();

    const {
        data: streamLinkData,
        isLoading: streamLinkIsLoading,
        isFetching: streamLinkIsFetching
    } = useListStreamLinkByGroupIdQuery({ groupId: groupId });

    if(streamLinkIsLoading){
        return(
            <Loader height={"100px"} width={"100px"} />
        );
    }

    if(!streamLinkData) {
        return (
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", height: "100%" }}>
                <div style={{ width: "100%", textAlign: "center", fontSize: "1vw" }}>
                    404 Not found
                </div>
            </div>
        );
    }
    
    return (
        <div style={{ ...style, display: "flex", flexDirection: "column", rowGap: "0.5vw" }}>
            {streamLinkData.map(streamLink => <StreamLinkView key={streamLink.streamLinkId} streamLink={streamLink} />)}
        </div>
    );
}