import React from "react";
import { default as ReactDatePicker } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export function DatePicker({ style, value, maxDate, minDate, onChange }: { 
    style?: React.CSSProperties; 
    value: Date;
    maxDate?: Date;
    minDate?: Date; 
    onChange: (value: Date) => void;  
}) {
    return (<ReactDatePicker
        dateFormat="dd/MM/yyyy"
        selected={value}
        maxDate={maxDate}
        minDate={minDate}
        onChange={(date) => onChange(date)} />);
}