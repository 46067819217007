import React from "react";
import { useTable, Column } from "react-table";
import {useAppSelector} from "../app/hooks";
import {currentColors} from "../slices/style";

const thStyle: React.CSSProperties = { padding: "calc(0.2vw + 2px) calc(0.3vw + 3px)" }
const tdStyle: React.CSSProperties = { padding: "calc(0.2vw + 2px) calc(0.3vw + 3px)" }
export function Table<T>({ columns, data, style }: { columns: Array<Column>; data: Array<T>; style?: React.CSSProperties; }) {
    const colors = useAppSelector(currentColors);
    // const memoData = React.useMemo(() => data, []);
    // const memoColumns = React.useMemo(() => columns, []);
    const tableInstance = useTable({ columns: columns, data: data })
    
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        footerGroups,
        rows,
        prepareRow,
    } = tableInstance

    return (

        // apply the table props
        <table {...getTableProps()} style={{ 
            ...getTableBodyProps().style,
            borderCollapse: "collapse",
            fontSize: "calc(0.5vw + 5px)",
            boxShadow: "0 0 20px rgba(0, 0, 0, 0.15)",
            ...style
        }}>
            <thead>
            {// Loop over the header rows
                headerGroups.map(headerGroup => (
                    // Apply the header row props
                    <tr {...headerGroup.getHeaderGroupProps()} style={{
                        backgroundColor: colors.bg2,
                        color: colors.text2,
                        textAlign: "left",
                        ...headerGroup.getHeaderGroupProps().style,
                    }}>
                        {// Loop over the headers in each row
                            headerGroup.headers.map(column => (
                                // Apply the header cell props
                                <th {...column.getHeaderProps()} style={{ ...column.getHeaderProps().style, ...thStyle }}>
                                    {// Render the header
                                        column.render('Header')}
                                </th>
                            ))}
                    </tr>
                ))}
            </thead>
            {/* Apply the table body props */}
            <tbody {...getTableBodyProps()}>
            {// Loop over the table rows
                rows.map((row, index) => {
                    // Prepare the row for display
                    prepareRow(row);
                    let isLast = index + 1 === rows.length;
                    let isOdd = index % 2 !== 0;
                    return (
                        // Apply the row props
                        <tr {...row.getRowProps()} style={{ 
                            ...row.getRowProps().style, 
                            borderBottom: isLast ? `2px solid ${colors.bg4}` : `1px solid ${colors.bg4}`,
                            backGroundColor: `${isOdd ? colors.bg2 : colors.bg1}`,
                        }}>
                            {// Loop over the rows cells
                                row.cells.map(cell => {
                                    // Apply the cell props
                                    return (
                                        <td {...cell.getCellProps()} style={{ ...cell.getCellProps().style, ...tdStyle }}>
                                            {// Render the cell contents
                                                cell.render('Cell')}
                                        </td>
                                    )
                                })}
                        </tr>
                    )
                })}
            </tbody>
            <tfoot>
            {footerGroups.slice(0, 1).filter(group => group.headers.some(x => x.Footer)).map(group => (
                    <tr {...group.getFooterGroupProps()}
                        style={{
                            borderBottom: `2px solid ${colors.bg4}`,
                            backGroundColor: `${colors.bg1}`,
                            ...group.getFooterGroupProps().style,
                        }}>
                        {group.headers.map(column => (
                            <td {...column.getFooterProps()} style={{ ...column.getFooterProps().style, ...tdStyle }}>{column.Footer && column.render('Footer')}</td>
                        ))}
                    </tr>
                ))}
            </tfoot>
        </table>
    )
}