export const colors1 = {
    text1: "#0f0f0f",
    text2: "#282828",
    text3: "#484848",
    text4: "#007fff",
    bg1: "#ffffff",
    bg2: "#ffffff",
    bg3: "#ffffff",
    bg4: "#ffffff",
}

export const colors2 = {
    text1: "#ffffff",
    text2: "#ffffff",
    text3: "#ffffff",
    text4: "#007fff",
    bg1: "#0f0f0f",
    bg2: "#282828",
    bg3: "#484848",
    bg4: "#686868"
}

export interface ColorPattern {
    text1: string;
    text2: string;
    text3: string;
    text4: string;
    bg1: string;
    bg2: string;
    bg3: string;
    bg4: string;
}

export const colorPalette = ['#FF6633', '#FFB399', '#FF33FF', '#FFFF99', '#00B3E6',
    '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
    '#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A',
    '#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
    '#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC',
    '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
    '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680',
    '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
    '#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3',
    '#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF']