import React from "react";
import {useCreateStreamEventMutation} from "../api/psApi";
import {Hearts} from "react-loader-spinner";
import {buttonStyle, errorStyle, linkStyle, smTitleStyle} from "../styles/baseStyles";
import {useAppSelector} from "../app/hooks";
import {currentColors} from "../slices/style";

export function GroupCreateLiveStream({ groupId }: { groupId: number }) {
    const [createStreamEvent, createStreamEventStatus] = useCreateStreamEventMutation();
    const colors = useAppSelector(currentColors);

    const buttonPanelStyle: React.CSSProperties = {
        ...linkStyle,
        flex: "0 0 auto",
        width: "calc(10vw + 100px)",
        height: "calc(17.8vw + 178px)",
        minWidth: 200,
        padding: "calc(0.8vw + 8px) calc(1vw + 10px)",
        border: `2px solid ${colors.text1}`,
        color: colors.text1,
        backgroundColor: colors.bg1,
        borderRadius: "10px",
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        rowGap: "calc(1vw + 10px)"
    };
    const featureListStyle: React.CSSProperties = { 
        listStyleType: "circle", 
        padding: "0px 0px 0px 20px", 
        margin: 0,
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        rowGap: "calc(0.5vw + 5px)"
    };
    const featureStyle: React.CSSProperties = { fontSize: "calc(0.6vw + 6px)" };
    
    return (
        <div style={{  }}>
            {createStreamEventStatus.isLoading
            && <Hearts
                height="300"
                width="300"
                color="#ff0000"
                ariaLabel="Live streams loading"
                wrapperStyle={{alignSelf: "center"}}
                wrapperClass=""
                visible={true}
            />}
            {!createStreamEventStatus.isLoading
            && <div style={{ 
                display: "flex", 
                flexDirection: "row", 
                alignItems: "center", 
                columnGap: "calc(2vw + 20px)", 
                rowGap: "calc(2vw + 20px)", 
                flexWrap: "wrap" }}>
                <div style={{ ...buttonPanelStyle }} className={"hover-azure"}
                     onClick={(e) => createStreamEvent({ createStreamEventModel: { groupId: groupId, streamType: "OnSite" } })}>
                    <div style={{ ...smTitleStyle, textAlign: "center" }}>PattayaGirls.tv</div>
                    <ul style={{ ...featureListStyle }}>
                        <li style={{ ...featureStyle }}>Users can watch on pattayagirls.tv</li>
                        <li style={{ ...featureStyle }}>Users can order and pay on pattayagirls.tv</li>
                        <li style={{ ...featureStyle }}>Users can chat pattayagirls.tv</li>
                    </ul>
                </div>
                <div style={{ ...buttonPanelStyle }} className={"hover-azure"}
                     onClick={(e) => createStreamEvent({ createStreamEventModel: { groupId: groupId, streamType: "OnAndOffSite" } })}>
                    <div style={{ ...smTitleStyle, textAlign: "center" }}>Multi Platform</div>
                    <ul style={{ ...featureListStyle }}>
                        <li style={{ ...featureStyle }}>Users can watch on pattayagirls.tv</li>
                        <li style={{ ...featureStyle }}>Users can order and pay on pattayagirls.tv</li>
                        <li style={{ ...featureStyle }}>Users can chat pattayagirls.tv</li>
                        <li style={{ ...featureStyle }}>Users can see your link to other stream</li>
                    </ul>
                </div>
                <div style={{ ...buttonPanelStyle }} className={"hover-azure"}
                     onClick={(e) => createStreamEvent({ createStreamEventModel: { groupId: groupId, streamType: "Offsite" } })}>
                    <div style={{ ...smTitleStyle, textAlign: "center" }}>Other platforms</div>
                    <ul style={{ ...featureListStyle }}>
                        <li style={{ ...featureStyle }}>Users can order and pay on pattayagirls.tv</li>
                        <li style={{ ...featureStyle }}>Users can see your link to other stream</li>
                    </ul>
   
                </div>
            </div>}
        </div>
    );
}