import React, {useState} from "react";
import {useAppDispatch, useAppSelector} from "../app/hooks";
import {currentColors} from "../slices/style";
import {
    CreateStreamLinkModel,
    useCreateStreamLinkMutation, useDeleteStreamLinkMutation,
    useListStreamLinkByGroupIdQuery,
} from "../api/psApi";
import {ReactComponent as FacebookIcon} from "../icons/facebookIcon.svg";
import {ReactComponent as YoutubeIcon} from "../icons/youtubeIcon.svg";
import {linkStyle} from "../styles/baseStyles";
import {ReactComponent as MainIcon} from "../icons/mainIcon.svg";
import {Link} from "react-router-dom";
import {usePopper} from "react-popper";
import {StreamLinkForm} from "./StreamLinkForm";
import {ReactComponent as DeleteIcon} from "../icons/deleteIcon.svg";
import {Loader} from "./Loader";
import {StreamLinkView} from "./StreamLinkView";

const streamLinkStyle: React.CSSProperties = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    columnGap: "0.5vw",
    fontSize: "calc(10px + 0.8vw)",
}
export function GroupStreamLink({ style, groupId }: { style?: React.CSSProperties; groupId: number }) {
    const colors = useAppSelector(currentColors);
    const dispatch = useAppDispatch();

    const {
        data: streamLinkData,
        isLoading: streamLinkIsLoading,
        isFetching: streamLinkIsFetching
    } = useListStreamLinkByGroupIdQuery({ groupId: groupId });

    const [addLinkOpened, updateAddLinkOpened] = useState(false);
    const [addLinkRef, setAddLinkRef] = useState(null);
    const [addLinkPopperElement, setAddLinkPopperElement] = useState(null);
    const { styles: stylesAdd, attributes: attributesAdd } = usePopper(addLinkRef, addLinkPopperElement, {
        placement: "right-start",
    });

    const [ createLinkPost, createLinkState ] = useCreateStreamLinkMutation();
    const [ deleteLinkPost, deleteLinkState ] = useDeleteStreamLinkMutation();
    
    if(streamLinkIsLoading){
        return(
            <Loader height={"100px"} width={"100px"} />
        );
    }

    if(!streamLinkData) {
        return (
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", height: "100%" }}>
                <div style={{ width: "100%", textAlign: "center", fontSize: "1vw" }}>
                    404 Not found
                </div>
            </div>
        );
    }
    
    const iconStyle = { height: "calc(10px + 0.8vw)", width: "calc(10px + 0.8vw)" };
    return (
        <div style={{
            ...style,
            display: "flex", 
            flexDirection: "column",
            rowGap: "1vw"
        }}>
            <div>
                <div style={{display: "flex", flexDirection: "row"}}>
                    <div ref={setAddLinkRef} style={{...linkStyle, fontSize: "calc(0.8vw + 8px)" }}
                         onClick={(e) => updateAddLinkOpened(!addLinkOpened)}>
                        + Add stream link
                    </div>
                </div>
                {addLinkOpened && <div ref={setAddLinkPopperElement}
                                       style={{
                                           ...stylesAdd.popper,
                                           left: "0.5vw",
                                           color: colors.text2,
                                           backgroundColor: colors.bg2,
                                           padding: "0.5vw",
                                           zIndex: 10,
                                           border: `1px solid ${colors.text1}`
                                       }} {...attributesAdd.popper}>
                    <StreamLinkForm type={"Create"} 
                                    onSubmit={(values: CreateStreamLinkModel) => {
                                        values.groupId = groupId;
                                        return createLinkPost({ createStreamLinkModel: values })
                                            .then(d => {
                                                updateAddLinkOpened(false);
                                                return d;
                                            });
                                    }} 
                                    onRequestClose={() => updateAddLinkOpened(false)} />
                </div>}
            </div>
            <div style={{ ...style, 
                display: "flex", 
                flexDirection: "row", 
                columnGap: "calc(1vw + 10px)", 
                flexWrap: "wrap",
                alignItems: "center"
            }}>
                {streamLinkData.map(streamLink => <div key={streamLink.streamLinkId} style={{
                    ...streamLinkStyle
                }}>
                    <StreamLinkView streamLink={streamLink} />
                    <DeleteIcon style={{ ...linkStyle, height: "calc(6px + 0.6vw)", width: "calc(6px + 0.6vw)" }} 
                                onClick={(e) => deleteLinkPost({deleteStreamLinkModel: { streamLinkId: streamLink.streamLinkId }})} />
                </div>)}
            </div>
        </div>
    );
}