import React from "react";
import {useListGroupQuery, useListLiveStreamEventQuery} from "../api/psApi";
import {useAppDispatch, useAppSelector} from "../app/hooks";
import {push} from "redux-first-history";
import {currentColors} from "../slices/style";
import {VideoPlayer} from "./VideoPlayer";
import {Loader} from "./Loader";

export function Groups({ style }: {  style?: React.CSSProperties}) {
    const dispatch = useAppDispatch();
    const {
        data: listData,
        isFetching: listIsFetching,
        isLoading: listIsLoading,
    } = useListGroupQuery();
    const colors = useAppSelector(currentColors);

    if(listIsLoading){
        return(
            <Loader height={"300px"} width={"300px"} />
        );
    }

    if(!listData) {
        return (<div style={{ display: "flex", flexDirection: "row", alignItems: "center", height: "100%" }}>
            <div style={{ width: "100%", textAlign: "center", fontSize: "2vw" }}>
                Error loading data
            </div>
        </div>);
    }
    
    return (
        <div style={{ display: "flex", flexDirection: "row", alignItems: "start", flexWrap: "wrap", ...style }}>
            {listData.map(group => <div key={group.groupId} style={{
                color: colors.text1,
                display: "grid",
                gridTemplateRows: "auto auto",
                gridTemplateColumns: "1fr auto",
                cursor: "pointer"
            }}
                    onClick={(e) => { dispatch(push(`/group/${group.groupId}`)); }}>
                <img style={{ 
                    gridRow: "1 / 2", 
                    gridColumn: "1 / 3",
                    height: "calc(4vw + 40px)",
                }} src={`/File/GroupPic?md5=${encodeURIComponent(group.imageMd5)}`} />
                <div style={{ gridRow: "2 / 3", gridColumn: "1 / 2", textAlign: "center", fontSize: "calc(0.8vw + 8px)" }}>
                    {group.name}
                </div>
            </div>)}
        </div>
    );
}