import React, {useState} from "react";
import {RegisterForm} from "./RegisterForm";

export function Register() {
    return (
        <div style={{display: "flex", flexDirection: "row", alignItems: "center", height: "100%"}}>
            <div style={{display: "flex", flexDirection: "column", alignItems: "center", width: "100%"}}>
                <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                    <RegisterForm style={{width: "calc(20vw + 200px)" }}/>
                </div>
            </div>
        </div>
    );
}