import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import * as SignalR from "@aspnet/signalr";
import {ChatMessageModel} from "./psApi";

export const listChatApi = createApi({
    reducerPath: "hubChat",
    baseQuery: fetchBaseQuery({ baseUrl: '/' }),
    endpoints: (build) => ({
        getChatMessagesByChatSessionId: build.query<ChatMessageModel[], { chatSessionId: number }>({
            query: (queryArg) => ({
                url: `/api/Chat/ListChatMessageByChatSessionId`,
                params: {
                    chatSessionId: queryArg.chatSessionId
                },
                prepareHeaders: headers => {
                    return headers
                }
            }),
            async onCacheEntryAdded(
                arg,
                { updateCachedData, cacheDataLoaded, cacheEntryRemoved }
            ) {
                console.log("Connecting WS");
                // create a websocket connection when the cache subscription starts
                const connection = new SignalR.HubConnectionBuilder()
                    .withUrl("/hubs/chat", { accessTokenFactory: async () => {
                            let response = await fetch("/api/account/GenerateWsToken");
                            // console.log("response", response);
                            if (response.ok) {
                                let result = await response.json();
                                // console.log("token", result);
                                return result.token;
                            }
                            return undefined;
                        } })
                    .build();

                try {
                    // wait for the initial query to resolve before proceeding
                    await cacheDataLoaded

                    // when data is received from the socket connection to the server,
                    // if it is a message and for the appropriate channel,
                    // update our query result with the received message
                    connection.on('MessageCreated', (message : ChatMessageModel) => {
                        updateCachedData((draft) => {
                            draft.push(message);
                        });
                    });
                    connection.start().then(() => {
                        connection.send("ConnectToSession", arg.chatSessionId);
                    }).catch((err) => {
                        return console.error(`Failed to connect to session ${arg.chatSessionId}`,err.toString());
                    });
                } catch {
                    // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
                    // in which case `cacheDataLoaded` will throw
                }

                
                // cacheEntryRemoved will resolve when the cache subscription is no longer active
                await cacheEntryRemoved
                // perform cleanup steps once the `cacheEntryRemoved` promise resolves
                connection.stop();
            },
        }),
    }),
});

export const { useGetChatMessagesByChatSessionIdQuery } = listChatApi;