import React from "react";
import {buttonStyle, linkStyle, smInputStyle, smLabelStyle} from "../styles/baseStyles";
import {FormField} from "./FormField";
import {useForm} from "react-hook-form";
import {CreateStreamLinkModel, StreamLinkType, UpdateStreamLinkModel} from "../api/psApi";
import {ControlSelect} from "./ControlSelect";

type StreamLinkFormType = "Create" | "Update";
type FormType = CreateStreamLinkModel | UpdateStreamLinkModel;

export function StreamLinkForm({ type, style, onSubmit, defaultValues, onRequestClose }: { 
    style?: React.CSSProperties;
    type: StreamLinkFormType;
    onSubmit: (values: FormType) => void;
    defaultValues?: FormType;
    onRequestClose: () => void;
}) {
    
    const {
        register: register
        , handleSubmit: handleSubmit
        , formState: formState
        , watch: watch
        , reset: reset
        , resetField: resetField
        , control: control
    } = useForm({
        mode: "onChange",
        criteriaMode: "all",
        defaultValues: defaultValues
    });

    const handleOnSubmit = (data: FormType) => {
        onSubmit(data);
    };
    
    const linkType = watch("type");
    return (
        <form onSubmit={handleSubmit(handleOnSubmit)}
              style={{
                  display: "grid",
                  gridTemplateRows: "auto auto auto auto",
                  gridTemplateColumns: "auto 1fr auto",
                  gridRowGap: "calc(0.3vw + 3px)",
                  gridColumnGap: "calc(0.5vw + 5px)"
              }}>
            <div style={{gridRow: "1 / 2", gridColumn: "1 / 2", ...smLabelStyle}}>Type:</div>
            <FormField style={{gridRow: "1 / 2", gridColumn: "2 / 4"}} errors={formState.errors}
                       name={"type"}>
                <ControlSelect
                    control={control}
                    isSearchable={false}
                    name={"type"}
                    options={[ 
                        { label: "Youtube", value: "Youtube" }
                        , { label: "Facebook", value: "Facebook" }
                    ] as Array<{ label:string, value: StreamLinkType }>} />
            </FormField>
            <div style={{gridRow: "2 / 3", gridColumn: "1 / 2", ...smLabelStyle}}>Url:</div>
            <FormField style={{gridRow: "2 / 3", gridColumn: "2 / 4"}} errors={formState.errors}
                       name={"url"}>
                <input type={"text"} 
                       style={{ ...smInputStyle }} 
                       {...register("url", {
                           required: "This field is required",
                           pattern: linkType === "Youtube"
                               ? {
                                   message: "Must be a Youtube link",
                                   value: /http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-\_]*)(&(amp;)?‌​[\w\?‌​=]*)?/
                               }
                               : {
                                   message: "Must be a Facebook link",
                                   value: /(?:https?:\/\/)?(?:www\.)?(mbasic.facebook|m\.facebook|facebook|fb)\.(com|me)\/(?:(?:\w\.)*#!\/)?(?:pages\/)?(?:[\w\-\.]*\/)*([\w\-\.]*)/
                               }
                       })} />
            </FormField>
            <div style={{gridRow: "3 / 4", gridColumn: "1 / 2", ...smLabelStyle}}>Name:</div>
                <FormField style={{gridRow: "3 / 4", gridColumn: "2 / 4"}} errors={formState.errors}
                           name={"name"}>
                    <input type={"text"}
                           style={{ ...smInputStyle }}
                           {...register("name", { })} />
            </FormField>
            <div style={{
                gridRow: "4 / 5",
                gridColumn: "3 / 4",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                columnGap: "0.5vw"
            }}>
                <button style={{ ...buttonStyle }} onClick={(e) => onRequestClose()} type="button">Close</button>
                <button style={{ ...buttonStyle }} type="submit" disabled={!formState.isValid}>{type === "Create" ? "Add" : "Update"}</button>
            </div>
        </form>
    );
}