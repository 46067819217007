import React from "react";
import {useAppDispatch, useAppSelector} from "../app/hooks";
import {currentColors} from "../slices/style";
import {
    useGetGroupByGroupIdQuery,
    useGetLiveStreamEventByGroupIdQuery,
    useGetStreamEventByGroupIdQuery
} from "../api/psApi";
import {Hearts} from "react-loader-spinner";
import {linkStyle, titleStyle} from "../styles/baseStyles";
import {GroupStreamLinkView} from "./GroupStreamLinkView";
import Linkify from 'linkify-react';
import {Loader} from "./Loader";
import {VideoPlayer} from "./VideoPlayer";
import {push} from "redux-first-history";

export function GroupView({ style, groupId }: { style?: React.CSSProperties; groupId: number }) {
    const colors = useAppSelector(currentColors);
    const dispatch = useAppDispatch();

    const {
        data: groupData,
        isLoading: groupIsLoading,
        isFetching: groupIsFetching
    } = useGetGroupByGroupIdQuery({ groupId: groupId });

    const {
        data: streamData,
        isLoading: streamDataLoading,
        isFetching: streamDataFetching
    } = useGetLiveStreamEventByGroupIdQuery({ groupId: groupId });
    
    if(groupIsLoading){
        return(
            <Loader height={"300px"} width={"300px"} />
        );
    }

    if(!groupData) {
        return (<div style={{ display: "flex", flexDirection: "row", alignItems: "center", height: "100%" }}>
            <div style={{ width: "100%", textAlign: "center", fontSize: "2vw" }}>
                404 Not found
            </div>
        </div>);
    }

    return (
        <div style={{
            ...style,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            rowGap: "calc(1vw + 10px)"
        }}>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <img style={{ flex: "0 0 auto", height: "calc(15vw + 40px)", backgroundColor: "white" }} src={`/File/GroupPic?md5=${encodeURIComponent(groupData.imageMd5)}`} />
            </div>
            <div style={{ ...titleStyle }}>
                {groupData.name}
            </div>
            {!streamDataLoading 
            && streamData
            && <div>
                <div onClick={(e) => dispatch(push(`/stream/${groupId}`))}
                     style={{ position: "relative" }}>
                    <div style={{
                        ...linkStyle,
                        position: "absolute",
                        top: 0, left: 0, right: 0, bottom: 0,
                        display: "flex", flexDirection: "row", alignItems: "center"
                    }}>
                        <div style={{
                            textAlign: "center",
                            width: "100%",
                            fontSize: "calc(1vw + 10px)",
                            fontWeight: "bold",
                        }}>
                            Now live
                        </div>
                    </div>
                    <VideoPlayer playerProps={{
                        width: "calc(10vw + 100px)",
                        height: "calc(5.625vw + 56.25px)",
                        url: streamData.liveEndpointHls,
                        muted: true,
                        controls: false
                    }} />
                </div>
            </div>}
            <div style={{  }}>
                <GroupStreamLinkView groupId={groupData.groupId} />
            </div>
            <div style={{ whiteSpace: "pre-wrap", fontSize: "calc(10px + 0.8vw)" }}>
                <Linkify options={{ attributes: { target: '_blank', style: linkStyle } }}>{groupData.description}</Linkify>
            </div>
        </div>
    );
}