import React from "react";
import {Hearts} from "react-loader-spinner";


export function Loader({ style, height, width }: {  style?: React.CSSProperties, height: string, width: string }) {
    return (
        <div style={{display: "flex", flexDirection: "row", alignItems: "center", height: "100%", ...style }}>
            <div style={{display: "flex", flexDirection: "column", alignItems: "center", width: "100%"}}>
                <Hearts
                    height={height}
                    width={width}
                    color="#ff0000"
                    ariaLabel="Live streams loading"
                    wrapperStyle={{alignSelf: "center"}}
                    wrapperClass=""
                    visible={true}
                />
            </div>
        </div>
    );
}