import React from "react";
import {useParams} from "react-router-dom";
import {StreamChat} from "./StreamChat";
import {pageStyle} from "../styles/baseStyles";
import {useAppDispatch, useAppSelector} from "../app/hooks";
import {currentColors} from "../slices/style";
import {push} from "redux-first-history";
import {useGetLiveStreamEventByGroupIdQuery} from "../api/psApi";
import {Loader} from "./Loader";
import StreamEventView from "./StreamEventView";

export function ChatPopout({ }: {  }) {
    const dispatch = useAppDispatch();
    const {groupId} = useParams();
    const colors = useAppSelector(currentColors);

    if (!groupId
        || isNaN(parseInt(groupId))) {
        dispatch(push("/"));
        return <div>ERROR</div>;
    }

    const {
        data: streamData,
        isFetching: streamIsFetching,
        isLoading: streamIsLoading,
    } = useGetLiveStreamEventByGroupIdQuery({groupId: parseInt(groupId)});

    if (streamIsLoading) {
        return(
            <Loader height={"200px"} width={"200px"} />
        );
    }

    if (!streamData)
        return (
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", height: "100%" }}>
                <div style={{ width: "100%", textAlign: "center", fontSize: "2vw" }}>
                    Sorry this stream is offline
                </div>
            </div>
        );

    return (
        <div style={{ height: "720px", width: "405px" }}>
            <StreamChat style={{ height: "100%", fontSize: "20px" }} streamEventId={streamData.streamEventId} />
        </div>
    );
}