import React, {useEffect, useState} from "react";
import {buttonStyle, errorStyle, inputStyle, labelStyle} from "../styles/baseStyles";
import {useRequestResetPasswordMutation} from "../api/psApi";
import {useAppSelector} from "../app/hooks";
import {currentColors} from "../slices/style";

export function RecoverAccount({}: {}) {
    const colors = useAppSelector(currentColors);

    const [ email, updateEmail ] = useState("");
    const [ errorMsg, updateErrorMsg ] = useState("");
    const [ emailSent, updateEmailSent ] = useState(false);
    const [ counter, updateCounter ] = useState(0);
    useEffect(() => {
        const interval = setInterval(() => {
            updateCounter(Math.max(0, counter - 1));
        }, 1000);
        return () => clearInterval(interval);
    }, [counter]);
    const [ requestResetPasswordPost, requestResetPasswordState ] = useRequestResetPasswordMutation();
    
    return(
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", height: "100%" }}>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%" }}>
                <div style={{
                    display: "grid",
                    gridRow: "auto auto auto",
                    gridColumn: "auto 1fr auto",
                    columnGap: "calc(0.4vw + 4px)",
                    rowGap: "calc(0.6vw + 6px)"
                }}>
                    <div style={{ gridRow: "1 / 2", gridColumn: "1 / 4", fontSize: "calc(0.5vw + 5px)", color: colors.text4 }}>
                        {emailSent && "An email to reset your password has been sent to the address"}
                    </div>
                    <div style={{ ...labelStyle, gridRow: "2 / 3", gridColumn: "1 / 2" }}>Email:</div>
                    <input style={{ 
                        ...inputStyle, 
                        gridRow: "2 / 3", 
                        gridColumn: "2 / 4",
                        minWidth: "calc(11vw + 110px)"
                    }} type={"email"} value={email} onChange={(e) => updateEmail(e.target.value)} />
                    <div style={{ ...errorStyle, gridRow: "3 / 4", gridColumn: "1 / 3" }}>
                        {errorMsg}
                    </div>
                    <button type={"button"}
                            disabled={requestResetPasswordState.isLoading || counter > 0}
                            onClick={(e) => {
                                requestResetPasswordPost({ requestResetPasswordModel: { email: email } }).then((value: any) => {
                                    if(value.error) {
                                        updateErrorMsg(value.error?.data || "Failed to send email");
                                        updateEmailSent(false);
                                    } else{
                                        updateErrorMsg("");
                                        updateCounter(60);
                                        updateEmailSent(true);
                                    }
                                });
                            }}
                            style={{ 
                                ...buttonStyle, 
                                gridRow: "3 / 4", 
                                gridColumn: "3 / 4",
                                width: "calc(4vw + 40px)"
                            }}>{counter > 0 ? `(${counter})` : "Send email"}</button>
                </div>
            </div>
        </div>
    );
}