import React from "react";
import {useAppDispatch, useAppSelector} from "../app/hooks";
import {currentColors} from "../slices/style";
import {LadyModel} from "../api/psApi";
import {ReactComponent as LadyIcon} from "../icons/ladyIcon.svg";

export function Lady({ style, lady, imgStyle, onClick }: { 
    style?: React.CSSProperties, 
    lady: LadyModel, 
    imgStyle?: React.CSSProperties, 
    onClick?: () => void 
}) {
    const colors = useAppSelector(currentColors);
    const dispatch = useAppDispatch();

    return (
        <div onClick={(e) => {
            if(onClick){
                onClick();
            }
        }} style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
            ...style
        }}>
            {lady.imageMd5 && <img style={{ flex: "0 0 auto", height: "calc(8vw + 40px)", ...imgStyle }} src={`/File/LadyPic?md5=${encodeURIComponent(lady.imageMd5)}`} />}
            {!lady.imageMd5 && <LadyIcon style={{ flex: "0 0 auto", height: "calc(8vw + 40px)", width: "auto", ...imgStyle }} />}
            <div style={{ flex: "0 0 auto", textAlign: "center" }}>
                <span style={{ fontWeight: "bold", fontSize: "calc(0.6vw + 6px)", paddingRight: 6 }}>#{lady.number}</span>
                <span style={{ fontWeight: "bold", fontSize: "calc(0.6vw + 6px)" }}>{lady.name}</span>
            </div>
        </div>
    );
}