import React from "react";
import {StreamEventModel, useStartStreamEventMutation} from "../api/psApi";
import {useForm} from "react-hook-form";
import {Loader} from "./Loader";
import {UpdateStreamEventForm} from "./UpdateStreamEventForm";
import {VideoPlayer} from "./VideoPlayer";


export function GroupStartLiveStream({ streamEvent }: { streamEvent: StreamEventModel }) {
    const [ startStream, startStreamStatus ] = useStartStreamEventMutation();
    const { register: startRegister, formState: startFormState  } = useForm({ mode: "onChange", criteriaMode: "all" });

    if(startStreamStatus.isLoading){
        return (
            <Loader height={"10vw"} width={"10vw"} />
        );
    }

    const ingestUrl = streamEvent.ingestUrl;
    const ingestKey = streamEvent.ingestUrl.slice(streamEvent.ingestUrl.lastIndexOf('/') + 1, Number.MAX_SAFE_INTEGER);
    return (
        <div style={{ display: "flex", flexDirection: "column", rowGap: "2vw" }}>
            test: {streamEvent.ingestUrl}
            {streamEvent.type !== "Offsite"
            && <div style={{ display: "flex", flexDirection: "row", alignItems: "center", fontSize: "1vw", columnGap: "1vw" }}>
                <div style={{ flex: "0 0 auto" }}>Ingest URL for OBS: </div>
                <div style={{ fontWeight: "bold", display: "block" }}>{ingestUrl}</div>
            </div>}
            {streamEvent.type !== "Offsite"
            && <div style={{ display: "flex", flexDirection: "row", alignItems: "center", fontSize: "1vw", columnGap: "1vw" }}>
                <div style={{ flex: "0 0 auto" }}>Streaming key: </div>
                <div style={{ fontWeight: "bold", display: "block" }}>{ingestKey}</div>
            </div>}
            <div style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                rowGap: "2vw"
            }}>
                <UpdateStreamEventForm
                    streamEvent={streamEvent}
                    currentLadiesId={[]}
                    currentStreamLinkIds={[]}
                    submitText={"Start stream"}
                    style={{ flex: "0.5", minWidth: 500, padding: "0px 10vw 0px 0px" }}
                    onSubmit={(values => {
                        startStream({ updateStreamEventModel: values });
                    })} />
                {streamEvent.type !== "Offsite" && <div style={{
                    flex: "0.5",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    minWidth: 300,
                    maxWidth: 600
                }}>
                    <VideoPlayer playerProps={{
                        url: streamEvent.previewUrl,
                        controls: true,
                        muted: false
                    }} />
                    <div style={{ fontSize: "1vw" }}>Preview</div>
                </div>}
                
            </div>
        </div>
    );
}