import {useAppSelector} from "../app/hooks";
import {currentColors} from "../slices/style";
import {AppHead} from "./AppHead";
import {Outlet} from "react-router-dom";
import React from "react";

export function Layout({}: {}) {
    const colors = useAppSelector(currentColors);

    return (
        <div style={{
            display: "grid"
            , gridTemplateRows: "calc(2vw + 20px) 1fr"
            , gridTemplateColumns: "1fr"
            , height: '100vh'
            , overflowY: "hidden"
        }}>
            <div style={{
                gridRow: "1", gridColumn: "1"
                , backgroundColor: colors.bg1
                , color: colors.text1
                , padding: "0px calc(1vw + 20px)"
            }}>
                <AppHead style={{ height: "100%" }} />
            </div>
            <div style={{
                gridRow: "2"
                , gridColumn: "1"
                , backgroundColor: colors.bg1
                , color: colors.text1
                , overflowY: "auto"
            }}>
                <Outlet />
            </div>
        </div>
    );
}
