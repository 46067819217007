import React, {useEffect, useState} from "react";
import {Table} from "./Table";
import {
    LadyModel,
    LadyPayModel,
    PaymentOrderModel,
} from "../api/psApi";
import * as _ from "lodash";
import {linkStyle, smInputStyle} from "../styles/baseStyles";
import {ReactComponent as DeleteIcon} from "../icons/deleteIcon.svg";
import {ReactComponent as AddIcon} from "../icons/addIcon.svg";

export function LadiesPayTable({ style, paymentOrders, ladyPays, bonusAmounts, onDelete, onCreate } : {
    style?: React.CSSProperties; 
    paymentOrders: Array<PaymentOrderModel>;
    ladyPays: Array<LadyPayModel>;
    bonusAmounts: Array<{ quantity: number; amount: number }>;
    onDelete: (ladyPayId: number) => Promise<any>;
    onCreate: (ladyId: number, amount: number) => Promise<any>;
}) {
    const [drinkComs, updateDrinkComs] = useState({} as { [ladyId: number]: number });
    const [hasBonus, updateHasBonus] = useState({} as { [ladyId: number]: boolean });
    const [ladyPaysDic, updateLadyPaysDic] = useState({} as { [ladyId: number]: number });
    
    const ladies = _.sortBy(_.uniqBy(_.flatten(paymentOrders.map(x => x.ladyDrinks)).map(x => x.lady), x => x.ladyId), x => x.number);
    // const paymentTypes = _.sortBy(_.uniq(paymentOrders.map(x => x.paymentTypLabel)), x => x);

    const getLadyTotal = (ladyId: number) => {
        let drinks = _.sum(_.flatten(paymentOrders.map(x => x.ladyDrinks))
            .filter(x => x.lady.ladyId === ladyId)
            .map(x => x.quantity));
        let bonus = getLadyBonus(ladyId);
        return (bonus || 0) + (drinks * (drinkComs[ladyId] || 50));
    }
    const getLadyBonus = (ladyId: number) => {
        let canBonus = hasBonus[ladyId] === undefined || hasBonus[ladyId];
        if (!canBonus)
            return 0;
        
        let drinks = _.sum(_.flatten(paymentOrders.map(x => x.ladyDrinks))
            .filter(x => x.lady.ladyId === ladyId)
            .map(x => x.quantity));
        let bonus = _.sortBy(bonusAmounts, x => -x.amount)
            .find(x => x.quantity <= drinks);
        return bonus?.amount || 0;
    }
    
    useEffect(() => {
        updateDrinkComs({});
        updateHasBonus({});
        updateLadyPaysDic({});
    }, [paymentOrders])
    
    return (
        <Table<LadyModel> style={{...style}} columns={[
            {
                Header: 'Lady',
                id: "lady",
                accessor: d => `#${d.number} ${d.name}`,
            },
            {
                Header: 'Drinks',
                id: "drinks",
                accessor: d => _.sum(_.flatten(paymentOrders.map(x => x.ladyDrinks))
                    .filter(x => x.lady.ladyId === d.ladyId)
                    .map(x => x.quantity)),
            },
            {
                Header: 'Drink com.',
                id: "drinkCom",
                accessor: d => <input type={"number"}
                                      style={{...smInputStyle, width: "50px"}}
                                      value={drinkComs[d.ladyId] || 50}
                                      onChange={(e) => updateDrinkComs({
                                          ...drinkComs,
                                          [d.ladyId]: e.target.valueAsNumber || 0
                                      })}/>
            },
            {
                Header: 'Has bonus',
                id: "hasBonus",
                accessor: d => d.ladyId,
                Cell: row => <input type={"checkbox"}
                                    style={{...smInputStyle, width: "50px"}}
                                    checked={hasBonus[row.value] === undefined || hasBonus[row.value]}
                                    onChange={(e) => updateHasBonus({...hasBonus, [row.value]: e.target.checked})}/>
            },
            {
                Header: 'Com.',
                id: "totalCom",
                accessor: d => _.sum(_.flatten(paymentOrders.map(x => x.ladyDrinks))
                    .filter(x => x.lady.ladyId === d.ladyId)
                    .map(x => x.quantity)) * (drinkComs[d.ladyId] || 50),
                Cell: ({value}) => (value || 0)
            },
            {
                Header: 'Bonus.',
                id: "bonus",
                accessor: d => getLadyBonus(d.ladyId),
                Cell: ({value}) => (value || 0)
            },
            {
                Header: 'Total',
                id: "total",
                accessor: d => getLadyTotal(d.ladyId),
                Cell: ({value}) => (value || 0)
            },
            {
                Header: "Paid",
                id: "paid",
                accessor: d => {
                    let ladyPay = ladyPays.find(x => x.ladyId === d.ladyId);
                    if (ladyPay) {
                        return <input type={"number"}
                                      style={{...smInputStyle, width: 60}}
                                      disabled={true}
                                      value={ladyPay.amount}/>
                    } else {
                        let ladyTotal = getLadyTotal(d.ladyId);
                        return <input type={"number"}
                                      style={{...smInputStyle, width: 60}}
                                      onChange={(e) => updateLadyPaysDic({
                                          ...ladyPaysDic,
                                          [d.ladyId]: e.target.valueAsNumber
                                      })}
                                      value={(ladyPaysDic[d.ladyId] && ladyPaysDic[d.ladyId] !== 0)
                                          ? ladyPaysDic[d.ladyId]
                                          : ladyTotal}/>
                    }
                },
                Footer: info => {
                    return <div style={{fontWeight: "bold"}}>
                        {(_.sum(ladyPays.map(x => x.amount)) || 0)}
                    </div>;
                }
            },
            {
                Header: "",
                id: "actions",
                accessor: d => {
                    let ladyPay = ladyPays.find(x => x.ladyId === d.ladyId);
                    if (ladyPay) {
                        return <DeleteIcon style={{ 
                            ...linkStyle, 
                            width: "calc(0.5vw + 5px)", 
                            height: "calc(0.5vw + 5px)" 
                        }} onClick={(e) => onDelete(ladyPay.ladyPayId)}/>
                    } else {
                        let ladyTotal = getLadyTotal(d.ladyId);
                        
                        return <AddIcon style={{
                            ...linkStyle,
                            width: "calc(0.5vw + 5px)",
                            height: "calc(0.5vw + 5px)"
                        }} onClick={(e) => onCreate(d.ladyId, (ladyPaysDic[d.ladyId] && ladyPaysDic[d.ladyId] !== 0)
                            ? ladyPaysDic[d.ladyId]
                            : ladyTotal)} />
                    }
                }
            },
        ]
        } data={ladies}/>
    );
}