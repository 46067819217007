import React from "react";
import {Table} from "./Table";
import {PaymentOrderModel, PaymentTypeModel} from "../api/psApi";
import * as _ from "lodash";

export function PaymentTypeTotalTable({ style, paymentOrders } : { style?: React.CSSProperties; paymentOrders: Array<PaymentOrderModel> }) {
    const paymentTypes = _.sortBy(_.uniq(paymentOrders.map(x => x.paymentTypLabel)), x => x);
    const ladies = _.sortBy(_.uniqBy(_.flatten(paymentOrders.map(x => x.ladyDrinks)).map(x => x.lady), x => x.ladyId), x => x.number);

    return (
        <Table<PaymentTypeModel> style={{ ...style }} columns={[
            {
                Header: 'Payment',
                id: "payment",
                accessor: d => d,
            },
            {
                Header: 'Total THB B.F.',
                id: "totalNoFees",
                accessor: d => _.sum(paymentOrders
                    .filter(x => x.paymentTypLabel === d)
                    .map(x => x.amount * x.thbValue)),
                Cell: ({value}) => ((value || 0) / 100).toFixed(2)
            },
            {
                Header: 'Total THB A.F.',
                id: "totalFees",
                accessor: d => {
                    return _.sum(paymentOrders
                        .filter(x => x.paymentTypLabel === d)
                        .map(x => (x.amount * x.thbValue) - x.amount * x.thbValue * x.fees));
                },
                Cell: ({value}) => ((value || 0) / 100).toFixed(2),
                Footer: info => {
                    const total = _.sum(info.rows.map(x => x.values.totalFees));
                    return <div style={{ fontWeight: "bold" }}>{((total || 0) / 100).toFixed(2)}</div>;
                }
            },
            {
                Header: <div style={{ textAlign: "center" }}>Ladies drinks</div>,
                id: "ladies",
                columns: ladies.map(lady => ({
                    Header: `#${lady.number} ${lady.name}`,
                    id: `drinks-${lady.ladyId}`,
                    accessor: d => _.sum(_.flatten(paymentOrders
                        .filter(x => x.paymentTypLabel === d)
                        .map(x => x.ladyDrinks))
                        .filter(x => x.lady.ladyId === lady.ladyId)
                        .map(x => x.quantity))
                }))
            }
        ]
        } data={paymentTypes}/>
    );
}