import {colorPalette} from "../styles/colors";
import Linkify from "linkify-react";
import {linkStyle} from "../styles/baseStyles";
import React from "react";
import {ChatMessageModel} from "../api/psApi";


export function ChatView({ divScrollRef, chatMessages, style }: {
    divScrollRef: any;
    chatMessages: Array<ChatMessageModel>;
    style?: React.CSSProperties;
}) {
    return (
        <div style={{
            position: "relative",
            ...style
        }}>
            <div ref={divScrollRef} style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: "flex",
                flexDirection: "column",
                alignItems: "stretch",
                rowGap: 3,
                overflowY: "scroll",
                padding: "10px",
            }}>
                {chatMessages.map(message => <div key={message.chatMessageId}
                                                style={{whiteSpace: "pre-wrap", fontSize: "1em", wordBreak: "break-word"}}>
                    <span style={{paddingRight: 6, fontWeight: "bold", color: colorPalette[message.accountId % colorPalette.length]}}>{message.displayName}:</span>
                    <Linkify options={{attributes: {target: '_blank', style: linkStyle}}}>
                        <span dangerouslySetInnerHTML={{__html: message.content}}></span>
                    </Linkify>
                </div>)}
            </div>
        </div>
    );
}