import React, {useState} from "react";
import {useAppSelector} from "../app/hooks";
import {currentColors} from "../slices/style";
import {useCurrentAccountQuery, useUpdateAccountMutation} from "../api/psApi";
import {labelStyle, linkStyle, pageStyle, titleStyle, valueStyle} from "../styles/baseStyles";
import {AccountForm} from "./AccountForm";
import {Loader} from "./Loader";
import {Accordion} from "./Accordion";


export function AccountPortal({}: {}) {
    const colors = useAppSelector(currentColors);
    const { data: currentAccount,
        isLoading: currentAccountLoading,
        isFetching: currentAccountFetching
    } = useCurrentAccountQuery();
    const [ updateAccountPost, updateAccountState ] = useUpdateAccountMutation();
    const [ updateIsOpen, updateUpdateIsOpen ] = useState(false);
    
    if(currentAccountLoading)
        return <Loader height={"300px"} width={"300px"} />;
    if(!currentAccount)
        return <div>ERROR</div>;
    
    return (
        <div style={{ ...pageStyle, display: "flex", flexDirection: "column", rowGap: "calc(1vw + 10px)" }}>
            <div style={{ ...titleStyle }}>My account</div>
            <div style={{ 
                display: "flex", 
                flexDirection: "column", 
                alignItems: "stretch",
                backgroundColor: colors.bg1,
                color: colors.text1,
                maxWidth: "calc(25vw + 250px)",
                borderBottomLeftRadius: "10px",
                borderBottomRightRadius: "10px",
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
            }}>
                <div style={{
                    padding: "calc(0.8vw + 10px) calc(0.8vw + 10px)",
                    boxSizing: "border-box",
                    display: "grid",
                    gridTemplateRows: "auto auto auto",
                    gridTemplateColumns: "auto auto auto auto",
                    gridRowGap: "calc(0.5vw + 5px)",
                    gridColumnGap: "calc(0.6vw + 6px)",
                }}>
                    <div style={{ ...labelStyle, gridRow: "1 / 2", gridColumn: "1 / 2" }}>Username:</div>
                    <div style={{ ...valueStyle, gridRow: "1 / 2", gridColumn: "2 / 3" }}>{currentAccount.username}</div>

                    <div style={{ ...labelStyle, gridRow: "2 / 3", gridColumn: "1 / 2" }}>Display name:</div>
                    <div style={{ ...valueStyle, gridRow: "2 / 3", gridColumn: "2 / 3" }}>{currentAccount.displayName}</div>

                    <div style={{ ...labelStyle, gridRow: "3 / 4", gridColumn: "1 / 2" }}>Email:</div>
                    <div style={{ ...valueStyle, gridRow: "3 / 4", gridColumn: "2 / 3" }}>{currentAccount.email}</div>
                </div>
                <Accordion isOpen={updateIsOpen}
                           onChange={(val) => updateUpdateIsOpen(val)}
                           style={{
                               backgroundColor: colors.bg2,
                               color: colors.text2,
                               borderTopLeftRadius: 0,
                               borderTopRightRadius: 0,
                           }}
                           title={"Update account"}>
                    <AccountForm style={{  }}
                                 onSubmit={(values) => updateAccountPost({ updateAccountModel: values }).then((value: any) => {
                                     if(value && value.data){
                                         updateUpdateIsOpen(false);
                                     }
                                     return value;
                                 })}
                                 defaultValues={currentAccount} />
                </Accordion>
            </div>
        </div>
    );
}