import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface StreamEventState {
}

const initialState: StreamEventState = {

};

export const streamEventSlice = createSlice({
    name: 'streamEvent',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {

    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    // extraReducers: (builder) => {
    //     builder
    //         ;
    // },
});

export const {  } = streamEventSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

export default streamEventSlice.reducer;
