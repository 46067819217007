import React from "react";
import {smTitleStyle, titleStyle} from "../styles/baseStyles";
import {useAppDispatch, useAppSelector} from "../app/hooks";
import {currentColors} from "../slices/style";
import {useParams} from "react-router-dom";
import {useGetGroupByGroupIdQuery} from "../api/psApi";
import {Hearts} from "react-loader-spinner";
import {GroupLadies} from "./GroupLadies";
import {GroupStreamLink} from "./GroupStreamLink";
import {GroupUpdate} from "./GroupUpdate";
import {Loader} from "./Loader";

export function GroupPortal({ style }: { style?: React.CSSProperties }) {
    const { groupId } = useParams();
    const colors = useAppSelector(currentColors);
    const dispatch = useAppDispatch();

    const {
        data: groupData,
        isLoading: groupIsLoading,
        isFetching: groupIsFetching
    } = useGetGroupByGroupIdQuery({ groupId: parseInt(groupId) || 0 });

    if(groupIsLoading){
        return(
            <Loader height={"300px"} width={"300px"} />
        );
    }

    if(!groupData) {
        return (<div style={{ display: "flex", flexDirection: "row", alignItems: "center", height: "100%" }}>
            <div style={{ width: "100%", textAlign: "center", fontSize: "2vw" }}>
                Group not found
            </div>
        </div>);
    }

    return (
        <div style={{
            ...style,
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
            rowGap: "calc(0.8vw + 8px)"
        }}>
            <div style={{ ...smTitleStyle }}>{groupData.name}</div>
            <div style={{ color: colors.text2, backgroundColor: colors.bg2, padding: "calc(0.4vw + 4px)" }}>
                <GroupUpdate groupId={parseInt(groupId)} />
            </div>
            <div style={{ color: colors.text2, backgroundColor: colors.bg2, padding: "calc(0.4vw + 4px)" }}>
                <GroupLadies groupId={parseInt(groupId)} style={{ minHeight: "calc(2.5vw + 25px)" }} />
            </div>
            <div style={{ color: colors.text2, backgroundColor: colors.bg2, padding: "calc(0.4vw + 4px)" }}>
                <GroupStreamLink groupId={parseInt(groupId)} style={{ minHeight: "calc(2.5vw + 25px)" }} />
            </div>
        </div>
    );
}