import {useAppSelector} from "../app/hooks";
import {currentColors} from "../slices/style";
import {pageStyle, smTitleStyle, titleStyle} from "../styles/baseStyles";
import React from "react";
import {StreamEvents} from "./StreamEvents";
import {Groups} from "./Groups";

export function GroupStreams() {
    const colors = useAppSelector(currentColors);

    return (
        <div style={{ ...pageStyle }}>
            <div style={{
                height: "100%",
                display: "grid",
                gridTemplateRows: "auto auto auto 1fr",
                gridTemplateColumns: "1fr auto",
            }}>
                <div style={{ gridRow: "2 / 3", gridColumn: "1 / 2", padding: "calc(0.8vw + 8px) 0px" }}>
                    <Groups />
                </div>
                <div style={{ gridRow: "3 / 4", gridColumn: "1 / 3" }}>
                    <div style={{ ...smTitleStyle }}>
                        Live streams
                    </div>
                </div>
                <div style={{ gridRow: "4 / 5", gridColumn: "1 / 2", padding: "calc(0.8vw + 8px) 0px" }}>
                    <StreamEvents />
                </div>
            </div>
        </div>
    );
}