import { createSlice } from '@reduxjs/toolkit';
import {LoginResultModel} from "../api/psApi";


export interface AccountState {
}

const initialState: AccountState = {
};

export const isAuthenticated = (login: LoginResultModel): boolean => {
    return login && login.isAuthenticated && new Date() < new Date(login.validity);
}

export const accountSlice = createSlice({
    name: 'account',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {

    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    // extraReducers: (builder) => {
    //     builder
    //         ;
    // },
});

export const {  } = accountSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

export default accountSlice.reducer;
