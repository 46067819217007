import { emptySplitApi as api } from "./emptyApi";
export const addTagTypes = [
  "Login",
  "Account",
  "WsToken",
  "RequestResetPassword",
  "ResetPassword",
  "ChatMessage",
  "ChatSession",
  "CreateChatMessage",
  "Currency",
  "File",
  "Group",
  "Lady",
  "LadyStream",
  "LadyPay",
  "Order",
  "CurrentOrder",
  "LadyDrink",
  "PaymentType",
  "Payment",
  "PaymentToken",
  "StreamEvent",
  "StreamLink",
] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      login: build.mutation<LoginApiResponse, LoginApiArg>({
        query: (queryArg) => ({
          url: `/api/Account/Login`,
          method: "POST",
          body: queryArg.loginModel,
        }),
        invalidatesTags: ["Login", "Account"],
      }),
      register: build.mutation<RegisterApiResponse, RegisterApiArg>({
        query: (queryArg) => ({
          url: `/api/Account/Register`,
          method: "POST",
          body: queryArg.createAccountModel,
        }),
        invalidatesTags: ["Login", "Account"],
      }),
      logout: build.mutation<LogoutApiResponse, LogoutApiArg>({
        query: () => ({ url: `/api/Account/Logout`, method: "POST" }),
        invalidatesTags: ["Login", "Account"],
      }),
      currentLogin: build.query<CurrentLoginApiResponse, CurrentLoginApiArg>({
        query: () => ({ url: `/api/Account/CurrentLogin` }),
        providesTags: ["Login", "Account"],
      }),
      lastAccountCard: build.query<
        LastAccountCardApiResponse,
        LastAccountCardApiArg
      >({
        query: () => ({ url: `/api/Account/LastAccountCard` }),
        providesTags: ["Login", "Account"],
      }),
      currentAccount: build.query<
        CurrentAccountApiResponse,
        CurrentAccountApiArg
      >({
        query: () => ({ url: `/api/Account/CurrentAccount` }),
        providesTags: ["Login", "Account"],
      }),
      updateAccount: build.mutation<
        UpdateAccountApiResponse,
        UpdateAccountApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Account/UpdateAccount`,
          method: "POST",
          body: queryArg.updateAccountModel,
        }),
        invalidatesTags: ["Account"],
      }),
      generateWsToken: build.query<
        GenerateWsTokenApiResponse,
        GenerateWsTokenApiArg
      >({
        query: () => ({ url: `/api/Account/GenerateWsToken` }),
        providesTags: ["WsToken"],
      }),
      requestResetPassword: build.mutation<
        RequestResetPasswordApiResponse,
        RequestResetPasswordApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Account/RequestResetPassword`,
          method: "POST",
          body: queryArg.requestResetPasswordModel,
        }),
        invalidatesTags: ["RequestResetPassword"],
      }),
      resetPassword: build.mutation<
        ResetPasswordApiResponse,
        ResetPasswordApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Account/ResetPassword`,
          method: "POST",
          body: queryArg.resetPasswordModel,
        }),
        invalidatesTags: ["ResetPassword"],
      }),
      listChatMessageByChatSessionId: build.query<
        ListChatMessageByChatSessionIdApiResponse,
        ListChatMessageByChatSessionIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Chat/ListChatMessageByChatSessionId`,
          params: { chatSessionId: queryArg.chatSessionId },
        }),
        providesTags: ["ChatMessage"],
      }),
      getChatSessionByStreamEventId: build.query<
        GetChatSessionByStreamEventIdApiResponse,
        GetChatSessionByStreamEventIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Chat/GetChatSessionByStreamEventId`,
          params: { streamEventId: queryArg.streamEventId },
        }),
        providesTags: ["ChatSession"],
      }),
      createChatMessage: build.mutation<
        CreateChatMessageApiResponse,
        CreateChatMessageApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Chat/CreateChatMessage`,
          method: "POST",
          body: queryArg.createChatMessageModel,
        }),
        invalidatesTags: ["CreateChatMessage"],
      }),
      listCurrency: build.query<ListCurrencyApiResponse, ListCurrencyApiArg>({
        query: () => ({ url: `/api/Currency/ListCurrency` }),
        providesTags: ["Currency"],
      }),
      updateCurrencyThbValues: build.query<
        UpdateCurrencyThbValuesApiResponse,
        UpdateCurrencyThbValuesApiArg
      >({
        query: () => ({ url: `/api/Currency/UpdateCurrencyThbValues` }),
        providesTags: ["Currency"],
      }),
      getFileLadyPic: build.query<
        GetFileLadyPicApiResponse,
        GetFileLadyPicApiArg
      >({
        query: (queryArg) => ({
          url: `/File/LadyPic`,
          params: { md5: queryArg.md5 },
        }),
        providesTags: ["File"],
      }),
      getFileGroupPic: build.query<
        GetFileGroupPicApiResponse,
        GetFileGroupPicApiArg
      >({
        query: (queryArg) => ({
          url: `/File/GroupPic`,
          params: { md5: queryArg.md5 },
        }),
        providesTags: ["File"],
      }),
      listGroupByAccount: build.query<
        ListGroupByAccountApiResponse,
        ListGroupByAccountApiArg
      >({
        query: () => ({ url: `/api/Group/ListGroupByAccount` }),
        providesTags: ["Group", "Login"],
      }),
      updateGroup: build.mutation<UpdateGroupApiResponse, UpdateGroupApiArg>({
        query: (queryArg) => ({
          url: `/api/Group/UpdateGroup`,
          method: "POST",
          body: queryArg.updateGroupModel,
        }),
        invalidatesTags: ["Group"],
      }),
      getGroupByGroupId: build.query<
        GetGroupByGroupIdApiResponse,
        GetGroupByGroupIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Group/GetGroupByGroupId`,
          params: { groupId: queryArg.groupId },
        }),
        providesTags: ["Group"],
      }),
      listGroup: build.query<ListGroupApiResponse, ListGroupApiArg>({
        query: () => ({ url: `/api/Group/ListGroup` }),
        providesTags: ["Group"],
      }),
      followGroup: build.mutation<FollowGroupApiResponse, FollowGroupApiArg>({
        query: (queryArg) => ({
          url: `/api/Group/FollowGroup`,
          method: "POST",
          body: queryArg.followGroupModel,
        }),
        invalidatesTags: ["Group"],
      }),
      unFollowGroup: build.mutation<
        UnFollowGroupApiResponse,
        UnFollowGroupApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Group/UnFollowGroup`,
          method: "POST",
          body: queryArg.unFollowGroupModel,
        }),
        invalidatesTags: ["Group"],
      }),
      createLady: build.mutation<CreateLadyApiResponse, CreateLadyApiArg>({
        query: (queryArg) => ({
          url: `/api/Lady/CreateLady`,
          method: "POST",
          body: queryArg.createLadyModel,
        }),
        invalidatesTags: ["Lady"],
      }),
      updateLady: build.mutation<UpdateLadyApiResponse, UpdateLadyApiArg>({
        query: (queryArg) => ({
          url: `/api/Lady/UpdateLady`,
          method: "POST",
          body: queryArg.updateLadyModel,
        }),
        invalidatesTags: ["Lady"],
      }),
      listLadyByStreamEventId: build.query<
        ListLadyByStreamEventIdApiResponse,
        ListLadyByStreamEventIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Lady/ListLadyByStreamEventId`,
          params: {
            streamEventId: queryArg.streamEventId,
            current: queryArg.current,
          },
        }),
        providesTags: ["Lady", "LadyStream"],
      }),
      listLadyByGroupId: build.query<
        ListLadyByGroupIdApiResponse,
        ListLadyByGroupIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Lady/ListLadyByGroupId`,
          params: { groupId: queryArg.groupId },
        }),
        providesTags: ["Lady"],
      }),
      addLadyToStream: build.mutation<
        AddLadyToStreamApiResponse,
        AddLadyToStreamApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Lady/AddLadyToStream`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["LadyStream"],
      }),
      removeLadyFromStream: build.mutation<
        RemoveLadyFromStreamApiResponse,
        RemoveLadyFromStreamApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Lady/RemoveLadyFromStream`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["LadyStream"],
      }),
      listLadyPayByDay: build.query<
        ListLadyPayByDayApiResponse,
        ListLadyPayByDayApiArg
      >({
        query: (queryArg) => ({
          url: `/api/LadyPay/ListLadyPayByDay`,
          params: { dayDate: queryArg.dayDate, groupId: queryArg.groupId },
        }),
        providesTags: ["LadyPay"],
      }),
      createLadyPay: build.mutation<
        CreateLadyPayApiResponse,
        CreateLadyPayApiArg
      >({
        query: (queryArg) => ({
          url: `/api/LadyPay/CreateLadyPay`,
          method: "POST",
          body: queryArg.createLadyPayModel,
        }),
        invalidatesTags: ["LadyPay"],
      }),
      deleteLadyPay: build.mutation<
        DeleteLadyPayApiResponse,
        DeleteLadyPayApiArg
      >({
        query: (queryArg) => ({
          url: `/api/LadyPay/DeleteLadyPay`,
          method: "POST",
          body: queryArg.deleteLadyPayModel,
        }),
        invalidatesTags: ["LadyPay"],
      }),
      createOrder: build.mutation<CreateOrderApiResponse, CreateOrderApiArg>({
        query: (queryArg) => ({
          url: `/api/Order/CreateOrder`,
          method: "POST",
          body: queryArg.createOrderModel,
        }),
        invalidatesTags: ["Order", "CurrentOrder"],
      }),
      getCurrentOrder: build.query<
        GetCurrentOrderApiResponse,
        GetCurrentOrderApiArg
      >({
        query: () => ({ url: `/api/Order/GetCurrentOrder` }),
        providesTags: ["Order", "CurrentOrder"],
      }),
      listOrderByAccountId: build.query<
        ListOrderByAccountIdApiResponse,
        ListOrderByAccountIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Order/ListOrderByAccountId`,
          params: { accountId: queryArg.accountId },
        }),
        providesTags: ["Order"],
      }),
      listOrderByGroupId: build.query<
        ListOrderByGroupIdApiResponse,
        ListOrderByGroupIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Order/ListOrderByGroupId`,
          params: { orderId: queryArg.orderId },
        }),
        providesTags: ["Order"],
      }),
      listLadyDrinkByOrderId: build.query<
        ListLadyDrinkByOrderIdApiResponse,
        ListLadyDrinkByOrderIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Order/ListLadyDrinkByOrderId`,
          params: { orderId: queryArg.orderId },
        }),
        providesTags: ["Order", "LadyDrink"],
      }),
      cancelOrder: build.mutation<CancelOrderApiResponse, CancelOrderApiArg>({
        query: (queryArg) => ({
          url: `/api/Order/CancelOrder`,
          method: "POST",
          body: queryArg.cancelOrderModel,
        }),
        invalidatesTags: ["Order", "CurrentOrder"],
      }),
      payOrderByCard: build.mutation<
        PayOrderByCardApiResponse,
        PayOrderByCardApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Order/PayOrderByCard`,
          method: "POST",
          body: queryArg.payOrderByCardModel,
        }),
        invalidatesTags: ["Order", "CurrentOrder"],
      }),
      payOrderByCardExisting: build.mutation<
        PayOrderByCardExistingApiResponse,
        PayOrderByCardExistingApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Order/PayOrderByCardExisting`,
          method: "POST",
          body: queryArg.payOrderByCardExistingModel,
        }),
        invalidatesTags: ["Order", "CurrentOrder"],
      }),
      listPaymentTypeByGroupId: build.query<
        ListPaymentTypeByGroupIdApiResponse,
        ListPaymentTypeByGroupIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Payment/ListPaymentTypeByGroupId`,
          params: { groupId: queryArg.groupId },
        }),
        providesTags: ["PaymentType"],
      }),
      createPaymentType: build.mutation<
        CreatePaymentTypeApiResponse,
        CreatePaymentTypeApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Payment/CreatePaymentType`,
          method: "POST",
          body: queryArg.createPaymentTypeModel,
        }),
        invalidatesTags: ["PaymentType"],
      }),
      createPaymentOrder: build.mutation<
        CreatePaymentOrderApiResponse,
        CreatePaymentOrderApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Payment/CreatePaymentOrder`,
          method: "POST",
          body: queryArg.createPaymentOrderModel,
        }),
        invalidatesTags: ["Payment", "Order", "LadyDrink"],
      }),
      getPaymentOrderByOrderId: build.query<
        GetPaymentOrderByOrderIdApiResponse,
        GetPaymentOrderByOrderIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Payment/GetPaymentOrderByOrderId`,
          params: { orderId: queryArg.orderId },
        }),
        providesTags: ["Payment", "Order", "LadyDrink"],
      }),
      listPaymentOrder: build.query<
        ListPaymentOrderApiResponse,
        ListPaymentOrderApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Payment/ListPaymentOrder`,
          params: {
            FromDate: queryArg.fromDate,
            ToDate: queryArg.toDate,
            GroupId: queryArg.groupId,
            StreamEventId: queryArg.streamEventId,
          },
        }),
        providesTags: ["Payment", "Order", "LadyDrink"],
      }),
      deletePaymentOrderByPaymentId: build.mutation<
        DeletePaymentOrderByPaymentIdApiResponse,
        DeletePaymentOrderByPaymentIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/Payment/DeletePaymentOrderByPaymentId`,
          method: "POST",
          body: queryArg.deletePaymentOrderModel,
        }),
        invalidatesTags: ["Payment", "Order", "LadyDrink"],
      }),
      getPaymentToken: build.query<
        GetPaymentTokenApiResponse,
        GetPaymentTokenApiArg
      >({
        query: () => ({ url: `/api/RemotePayment/GetPaymentToken` }),
        providesTags: ["PaymentToken"],
      }),
      createStreamEvent: build.mutation<
        CreateStreamEventApiResponse,
        CreateStreamEventApiArg
      >({
        query: (queryArg) => ({
          url: `/api/StreamEvent/CreateStreamEvent`,
          method: "POST",
          body: queryArg.createStreamEventModel,
        }),
        invalidatesTags: ["StreamEvent"],
      }),
      updateStreamEvent: build.mutation<
        UpdateStreamEventApiResponse,
        UpdateStreamEventApiArg
      >({
        query: (queryArg) => ({
          url: `/api/StreamEvent/UpdateStreamEvent`,
          method: "POST",
          body: queryArg.updateStreamEventModel,
        }),
        invalidatesTags: ["StreamEvent", "LadyStream"],
      }),
      startStreamEvent: build.mutation<
        StartStreamEventApiResponse,
        StartStreamEventApiArg
      >({
        query: (queryArg) => ({
          url: `/api/StreamEvent/StartStreamEvent`,
          method: "POST",
          body: queryArg.updateStreamEventModel,
        }),
        invalidatesTags: ["StreamEvent"],
      }),
      stopStreamEvent: build.mutation<
        StopStreamEventApiResponse,
        StopStreamEventApiArg
      >({
        query: (queryArg) => ({
          url: `/api/StreamEvent/StopStreamEvent`,
          method: "POST",
          body: queryArg.stopStreamEventModel,
        }),
        invalidatesTags: ["StreamEvent"],
      }),
      listLiveStreamEvent: build.query<
        ListLiveStreamEventApiResponse,
        ListLiveStreamEventApiArg
      >({
        query: () => ({ url: `/api/StreamEvent/ListLiveStreamEvent` }),
        providesTags: ["StreamEvent"],
      }),
      getLiveStreamEventByGroupId: build.query<
        GetLiveStreamEventByGroupIdApiResponse,
        GetLiveStreamEventByGroupIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/StreamEvent/GetLiveStreamEventByGroupId`,
          params: { groupId: queryArg.groupId },
        }),
        providesTags: ["StreamEvent"],
      }),
      getStreamEventByGroupId: build.query<
        GetStreamEventByGroupIdApiResponse,
        GetStreamEventByGroupIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/StreamEvent/GetStreamEventByGroupId`,
          params: { groupId: queryArg.groupId },
        }),
        providesTags: ["StreamEvent"],
      }),
      createStreamLink: build.mutation<
        CreateStreamLinkApiResponse,
        CreateStreamLinkApiArg
      >({
        query: (queryArg) => ({
          url: `/api/StreamLink/CreateStreamLink`,
          method: "POST",
          body: queryArg.createStreamLinkModel,
        }),
        invalidatesTags: ["StreamLink"],
      }),
      updateStreamLink: build.mutation<
        UpdateStreamLinkApiResponse,
        UpdateStreamLinkApiArg
      >({
        query: (queryArg) => ({
          url: `/api/StreamLink/UpdateStreamLink`,
          method: "POST",
          body: queryArg.updateStreamLinkModel,
        }),
        invalidatesTags: ["StreamLink"],
      }),
      deleteStreamLink: build.mutation<
        DeleteStreamLinkApiResponse,
        DeleteStreamLinkApiArg
      >({
        query: (queryArg) => ({
          url: `/api/StreamLink/DeleteStreamLink`,
          method: "POST",
          body: queryArg.deleteStreamLinkModel,
        }),
        invalidatesTags: ["StreamLink"],
      }),
      listStreamLinkByGroupId: build.query<
        ListStreamLinkByGroupIdApiResponse,
        ListStreamLinkByGroupIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/StreamLink/ListStreamLinkByGroupId`,
          params: { groupId: queryArg.groupId },
        }),
        providesTags: ["StreamLink"],
      }),
      listStreamLinkByStreamEventId: build.query<
        ListStreamLinkByStreamEventIdApiResponse,
        ListStreamLinkByStreamEventIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/StreamLink/ListStreamLinkByStreamEventId`,
          params: { streamEventId: queryArg.streamEventId },
        }),
        providesTags: ["StreamLink", "StreamEvent"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as psApi };
export type LoginApiResponse = /** status 200 Success */ LoginResultModel;
export type LoginApiArg = {
  loginModel: LoginModel;
};
export type RegisterApiResponse = /** status 200 Success */ LoginResultModel;
export type RegisterApiArg = {
  createAccountModel: CreateAccountModel;
};
export type LogoutApiResponse = unknown;
export type LogoutApiArg = void;
export type CurrentLoginApiResponse =
  /** status 200 Success */ LoginResultModel;
export type CurrentLoginApiArg = void;
export type LastAccountCardApiResponse =
  /** status 200 Success */ AccountCardModel;
export type LastAccountCardApiArg = void;
export type CurrentAccountApiResponse = /** status 200 Success */ AccountModel;
export type CurrentAccountApiArg = void;
export type UpdateAccountApiResponse = /** status 200 Success */ AccountModel;
export type UpdateAccountApiArg = {
  updateAccountModel: UpdateAccountModel;
};
export type GenerateWsTokenApiResponse = /** status 200 Success */ WsTokenModel;
export type GenerateWsTokenApiArg = void;
export type RequestResetPasswordApiResponse = unknown;
export type RequestResetPasswordApiArg = {
  requestResetPasswordModel: RequestResetPasswordModel;
};
export type ResetPasswordApiResponse = unknown;
export type ResetPasswordApiArg = {
  resetPasswordModel: ResetPasswordModel;
};
export type ListChatMessageByChatSessionIdApiResponse =
  /** status 200 Success */ ChatMessageModel[];
export type ListChatMessageByChatSessionIdApiArg = {
  chatSessionId?: number;
};
export type GetChatSessionByStreamEventIdApiResponse =
  /** status 200 Success */ ChatSessionModel;
export type GetChatSessionByStreamEventIdApiArg = {
  streamEventId?: number;
};
export type CreateChatMessageApiResponse =
  /** status 200 Success */ ChatMessageModel;
export type CreateChatMessageApiArg = {
  createChatMessageModel: CreateChatMessageModel;
};
export type ListCurrencyApiResponse = /** status 200 Success */ CurrencyModel[];
export type ListCurrencyApiArg = void;
export type UpdateCurrencyThbValuesApiResponse = unknown;
export type UpdateCurrencyThbValuesApiArg = void;
export type GetFileLadyPicApiResponse = unknown;
export type GetFileLadyPicApiArg = {
  md5?: string;
};
export type GetFileGroupPicApiResponse = unknown;
export type GetFileGroupPicApiArg = {
  md5?: string;
};
export type ListGroupByAccountApiResponse =
  /** status 200 Success */ GroupModel[];
export type ListGroupByAccountApiArg = void;
export type UpdateGroupApiResponse = /** status 200 Success */ GroupModel;
export type UpdateGroupApiArg = {
  updateGroupModel: UpdateGroupModel;
};
export type GetGroupByGroupIdApiResponse = /** status 200 Success */ GroupModel;
export type GetGroupByGroupIdApiArg = {
  groupId?: number;
};
export type ListGroupApiResponse = /** status 200 Success */ GroupModel[];
export type ListGroupApiArg = void;
export type FollowGroupApiResponse = unknown;
export type FollowGroupApiArg = {
  followGroupModel: FollowGroupModel;
};
export type UnFollowGroupApiResponse = unknown;
export type UnFollowGroupApiArg = {
  unFollowGroupModel: UnFollowGroupModel;
};
export type CreateLadyApiResponse = /** status 200 Success */ LadyModel;
export type CreateLadyApiArg = {
  createLadyModel: CreateLadyModel;
};
export type UpdateLadyApiResponse = /** status 200 Success */ LadyModel;
export type UpdateLadyApiArg = {
  updateLadyModel: UpdateLadyModel;
};
export type ListLadyByStreamEventIdApiResponse =
  /** status 200 Success */ LadyModel[];
export type ListLadyByStreamEventIdApiArg = {
  streamEventId?: number;
  current?: boolean;
};
export type ListLadyByGroupIdApiResponse =
  /** status 200 Success */ LadyModel[];
export type ListLadyByGroupIdApiArg = {
  groupId?: number;
};
export type AddLadyToStreamApiResponse = unknown;
export type AddLadyToStreamApiArg = {
  body: {
    StreamEventId?: number;
    LadyId?: number;
  };
};
export type RemoveLadyFromStreamApiResponse = unknown;
export type RemoveLadyFromStreamApiArg = {
  body: {
    StreamEventId?: number;
    LadyId?: number;
  };
};
export type ListLadyPayByDayApiResponse =
  /** status 200 Success */ LadyPayModel[];
export type ListLadyPayByDayApiArg = {
  dayDate?: string;
  groupId?: number;
};
export type CreateLadyPayApiResponse = /** status 200 Success */ LadyPayModel;
export type CreateLadyPayApiArg = {
  createLadyPayModel: CreateLadyPayModel;
};
export type DeleteLadyPayApiResponse = unknown;
export type DeleteLadyPayApiArg = {
  deleteLadyPayModel: DeleteLadyPayModel;
};
export type CreateOrderApiResponse = /** status 200 Success */ OrderModel;
export type CreateOrderApiArg = {
  createOrderModel: CreateOrderModel;
};
export type GetCurrentOrderApiResponse = /** status 200 Success */ OrderModel;
export type GetCurrentOrderApiArg = void;
export type ListOrderByAccountIdApiResponse =
  /** status 200 Success */ OrderModel[];
export type ListOrderByAccountIdApiArg = {
  accountId?: number;
};
export type ListOrderByGroupIdApiResponse =
  /** status 200 Success */ OrderModel[];
export type ListOrderByGroupIdApiArg = {
  orderId?: number;
};
export type ListLadyDrinkByOrderIdApiResponse =
  /** status 200 Success */ LadyDrinkModel[];
export type ListLadyDrinkByOrderIdApiArg = {
  orderId?: number;
};
export type CancelOrderApiResponse = unknown;
export type CancelOrderApiArg = {
  cancelOrderModel: CancelOrderModel;
};
export type PayOrderByCardApiResponse = unknown;
export type PayOrderByCardApiArg = {
  payOrderByCardModel: PayOrderByCardModel;
};
export type PayOrderByCardExistingApiResponse = unknown;
export type PayOrderByCardExistingApiArg = {
  payOrderByCardExistingModel: PayOrderByCardExistingModel;
};
export type ListPaymentTypeByGroupIdApiResponse =
  /** status 200 Success */ PaymentTypeModel[];
export type ListPaymentTypeByGroupIdApiArg = {
  groupId?: number;
};
export type CreatePaymentTypeApiResponse =
  /** status 200 Success */ PaymentTypeModel;
export type CreatePaymentTypeApiArg = {
  createPaymentTypeModel: CreatePaymentTypeModel;
};
export type CreatePaymentOrderApiResponse =
  /** status 200 Success */ PaymentOrderModel;
export type CreatePaymentOrderApiArg = {
  createPaymentOrderModel: CreatePaymentOrderModel;
};
export type GetPaymentOrderByOrderIdApiResponse =
  /** status 200 Success */ PaymentOrderModel;
export type GetPaymentOrderByOrderIdApiArg = {
  orderId?: number;
};
export type ListPaymentOrderApiResponse =
  /** status 200 Success */ PaymentOrderModel[];
export type ListPaymentOrderApiArg = {
  fromDate?: string;
  toDate?: string;
  groupId?: number;
  streamEventId?: number;
};
export type DeletePaymentOrderByPaymentIdApiResponse = unknown;
export type DeletePaymentOrderByPaymentIdApiArg = {
  deletePaymentOrderModel: DeletePaymentOrderModel;
};
export type GetPaymentTokenApiResponse =
  /** status 200 Success */ PaymentTokenModel;
export type GetPaymentTokenApiArg = void;
export type CreateStreamEventApiResponse =
  /** status 200 Success */ StreamEventModel;
export type CreateStreamEventApiArg = {
  createStreamEventModel: CreateStreamEventModel;
};
export type UpdateStreamEventApiResponse =
  /** status 200 Success */ StreamEventModel;
export type UpdateStreamEventApiArg = {
  updateStreamEventModel: UpdateStreamEventModel;
};
export type StartStreamEventApiResponse =
  /** status 200 Success */ StreamEventModel;
export type StartStreamEventApiArg = {
  updateStreamEventModel: UpdateStreamEventModel;
};
export type StopStreamEventApiResponse = unknown;
export type StopStreamEventApiArg = {
  stopStreamEventModel: StopStreamEventModel;
};
export type ListLiveStreamEventApiResponse =
  /** status 200 Success */ ViewStreamEventModel[];
export type ListLiveStreamEventApiArg = void;
export type GetLiveStreamEventByGroupIdApiResponse =
  /** status 200 Success */ ViewStreamEventModel;
export type GetLiveStreamEventByGroupIdApiArg = {
  groupId?: number;
};
export type GetStreamEventByGroupIdApiResponse =
  /** status 200 Success */ StreamEventModel;
export type GetStreamEventByGroupIdApiArg = {
  groupId?: number;
};
export type CreateStreamLinkApiResponse =
  /** status 200 Success */ StreamLinkModel;
export type CreateStreamLinkApiArg = {
  createStreamLinkModel: CreateStreamLinkModel;
};
export type UpdateStreamLinkApiResponse =
  /** status 200 Success */ StreamLinkModel;
export type UpdateStreamLinkApiArg = {
  updateStreamLinkModel: UpdateStreamLinkModel;
};
export type DeleteStreamLinkApiResponse = unknown;
export type DeleteStreamLinkApiArg = {
  deleteStreamLinkModel: DeleteStreamLinkModel;
};
export type ListStreamLinkByGroupIdApiResponse =
  /** status 200 Success */ StreamLinkModel[];
export type ListStreamLinkByGroupIdApiArg = {
  groupId?: number;
};
export type ListStreamLinkByStreamEventIdApiResponse =
  /** status 200 Success */ StreamLinkModel[];
export type ListStreamLinkByStreamEventIdApiArg = {
  streamEventId?: number;
};
export type LoginResultModel = {
  isAuthenticated?: boolean;
  validity?: string | null;
  roles?: string[] | null;
};
export type LoginModel = {
  username?: string | null;
  password?: string | null;
  rememberMe?: boolean;
};
export type CreateAccountModel = {
  username?: string | null;
  displayName?: string | null;
  email?: string | null;
  password?: string | null;
};
export type AccountCardModel = {
  accountCardId?: number;
  remoteId?: string | null;
  name?: string | null;
  lastDigits?: string | null;
  expirationMonth?: number;
  expirationYear?: number;
  accountId?: number;
};
export type AccountModel = {
  accountId?: number;
  username?: string | null;
  displayName?: string | null;
  email?: string | null;
  creationDate?: string;
};
export type UpdateAccountModel = {
  username?: string | null;
  displayName?: string | null;
  email?: string | null;
  currentPassword?: string | null;
  newPassword?: string | null;
};
export type WsTokenModel = {
  token?: string | null;
};
export type RequestResetPasswordModel = {
  email?: string | null;
};
export type ResetPasswordModel = {
  token?: string | null;
  password?: string | null;
};
export type ChatMessageModel = {
  chatMessageId?: number;
  content?: string | null;
  displayName?: string | null;
  creationDate?: string;
  chatSessionId?: number;
  accountId?: number;
};
export type ChatSessionModel = {
  chatSessionId?: number;
  title?: string | null;
  max10?: number;
  max60?: number;
  max600?: number;
  creationDate?: string;
  streamEventId?: number;
};
export type CreateChatMessageModel = {
  content?: string | null;
  chatSessionId?: number;
};
export type CurrencyModel = {
  currencyId?: number;
  code?: string | null;
  name?: string | null;
  symbol?: string | null;
  thbValue?: number;
};
export type GroupModel = {
  groupId?: number;
  name?: string | null;
  description?: string | null;
  imageId?: number;
  imageMd5?: string | null;
  following?: boolean;
  followingCount?: number;
  creationDate?: string;
};
export type UpdateGroupModel = {
  groupId?: number;
  name?: string | null;
  description?: string | null;
  imageBase64?: string | null;
  imageFilename?: string | null;
};
export type FollowGroupModel = {
  groupId?: number;
};
export type UnFollowGroupModel = {
  groupId?: number;
};
export type LadyModel = {
  ladyId?: number;
  name?: string | null;
  number?: number;
  imageId?: number | null;
  imageMd5?: string | null;
  groupId?: number;
};
export type CreateLadyModel = {
  name?: string | null;
  number?: number;
  imageFilename?: string | null;
  imageBase64?: string | null;
  groupId?: number;
};
export type UpdateLadyModel = {
  ladyId?: number;
  name?: string | null;
  number?: number;
  imageFilename?: string | null;
  imageBase64?: string | null;
};
export type LadyPayModel = {
  ladyPayId?: number;
  ladyId?: number;
  amount?: number;
  dayDate?: string;
  creationDate?: string;
};
export type CreateLadyPayModel = {
  amount?: number;
  ladyId?: number;
  dayDate?: string;
};
export type DeleteLadyPayModel = {
  ladyPayId?: number;
};
export type OrderType = "OnSite" | "OffSite";
export type OrderModel = {
  orderId?: number;
  type?: OrderType;
  paidDate?: string | null;
  canceledDate?: string | null;
  creationDate?: string;
  streamEventId?: number;
  accountId?: number;
};
export type CreateLadyDrinkModel = {
  ladyId?: number;
  quantity?: number;
};
export type CreateOrderModel = {
  streamEventId?: number;
  ladyDrinks?: CreateLadyDrinkModel[] | null;
};
export type LadyDrinkModel = {
  ladyDrinkId?: number;
  quantity?: number;
  lady?: LadyModel;
  orderId?: number;
};
export type CancelOrderModel = {
  orderId?: number;
};
export type PayOrderByCardModel = {
  orderId?: number;
  cardToken?: string | null;
};
export type PayOrderByCardExistingModel = {
  orderId?: number;
  cardRemoteId?: string | null;
};
export type PaymentTypeModel = {
  paymentTypeId?: number;
  label?: string | null;
  fees?: number | null;
};
export type CreatePaymentTypeModel = {
  label?: string | null;
  groupId?: number;
  fees?: number | null;
};
export type PaymentOrderModel = {
  paymentId?: number;
  amount?: number;
  thbValue?: number;
  fees?: number;
  creationDate?: string;
  paymentTypLabel?: string | null;
  currencyCode?: string | null;
  orderId?: number;
  groupId?: number;
  ladyDrinks?: LadyDrinkModel[] | null;
};
export type CreatePaymentOrderModel = {
  amount?: number;
  comment?: string | null;
  paymentTypeId?: number;
  currencyId?: number;
  groupId?: number;
  day?: string | null;
  ladyDrinks?: CreateLadyDrinkModel[] | null;
};
export type DeletePaymentOrderModel = {
  paymentId?: number;
};
export type PaymentTokenModel = {
  publicKey?: string | null;
};
export type StreamType = "OnSite" | "Offsite" | "OnAndOffSite";
export type StreamEventModel = {
  streamEventId?: number;
  title?: string | null;
  description?: string | null;
  remoteId?: string | null;
  type?: StreamType;
  ingestUrl?: string | null;
  previewUrl?: string | null;
  liveEndpointHls?: string | null;
  liveEndpointDash?: string | null;
  ladyDrinkPrice?: number;
  startedDate?: string | null;
  stoppedDate?: string | null;
  creationDate?: string;
  accountId?: number;
  groupId?: number;
};
export type CreateStreamEventModel = {
  streamType?: StreamType;
  groupId?: number;
};
export type UpdateStreamEventModel = {
  streamEventId?: number;
  title?: string | null;
  description?: string | null;
  ladiesId?: number[] | null;
  streamLinksId?: number[] | null;
};
export type StopStreamEventModel = {
  streamEventId?: number;
};
export type ViewStreamEventModel = {
  streamEventId?: number;
  type?: StreamType;
  title?: string | null;
  description?: string | null;
  imageMd5?: string | null;
  liveEndpointHls?: string | null;
  liveEndpointDash?: string | null;
  ladyDrinkPrice?: number;
  startedDate?: string | null;
  groupId?: number;
};
export type StreamLinkType = "Youtube" | "Facebook" | "Ps";
export type StreamLiveStatus = "Unknown" | "OffLine" | "Live";
export type StreamLinkModel = {
  streamLinkId?: number;
  name?: string | null;
  groupId?: number;
  url?: string | null;
  type?: StreamLinkType;
  liveStatus?: StreamLiveStatus;
};
export type CreateStreamLinkModel = {
  groupId?: number;
  name?: string | null;
  url?: string | null;
  type?: StreamLinkType;
};
export type UpdateStreamLinkModel = {
  streamLinkId?: number;
  name?: string | null;
  url?: string | null;
  type?: StreamLinkType;
};
export type DeleteStreamLinkModel = {
  streamLinkId?: number;
};
export const {
  useLoginMutation,
  useRegisterMutation,
  useLogoutMutation,
  useCurrentLoginQuery,
  useLastAccountCardQuery,
  useCurrentAccountQuery,
  useUpdateAccountMutation,
  useGenerateWsTokenQuery,
  useRequestResetPasswordMutation,
  useResetPasswordMutation,
  useListChatMessageByChatSessionIdQuery,
  useGetChatSessionByStreamEventIdQuery,
  useCreateChatMessageMutation,
  useListCurrencyQuery,
  useUpdateCurrencyThbValuesQuery,
  useGetFileLadyPicQuery,
  useGetFileGroupPicQuery,
  useListGroupByAccountQuery,
  useUpdateGroupMutation,
  useGetGroupByGroupIdQuery,
  useListGroupQuery,
  useFollowGroupMutation,
  useUnFollowGroupMutation,
  useCreateLadyMutation,
  useUpdateLadyMutation,
  useListLadyByStreamEventIdQuery,
  useListLadyByGroupIdQuery,
  useAddLadyToStreamMutation,
  useRemoveLadyFromStreamMutation,
  useListLadyPayByDayQuery,
  useCreateLadyPayMutation,
  useDeleteLadyPayMutation,
  useCreateOrderMutation,
  useGetCurrentOrderQuery,
  useListOrderByAccountIdQuery,
  useListOrderByGroupIdQuery,
  useListLadyDrinkByOrderIdQuery,
  useCancelOrderMutation,
  usePayOrderByCardMutation,
  usePayOrderByCardExistingMutation,
  useListPaymentTypeByGroupIdQuery,
  useCreatePaymentTypeMutation,
  useCreatePaymentOrderMutation,
  useGetPaymentOrderByOrderIdQuery,
  useListPaymentOrderQuery,
  useDeletePaymentOrderByPaymentIdMutation,
  useGetPaymentTokenQuery,
  useCreateStreamEventMutation,
  useUpdateStreamEventMutation,
  useStartStreamEventMutation,
  useStopStreamEventMutation,
  useListLiveStreamEventQuery,
  useGetLiveStreamEventByGroupIdQuery,
  useGetStreamEventByGroupIdQuery,
  useCreateStreamLinkMutation,
  useUpdateStreamLinkMutation,
  useDeleteStreamLinkMutation,
  useListStreamLinkByGroupIdQuery,
  useListStreamLinkByStreamEventIdQuery,
} = injectedRtkApi;
