import { useAppSelector, useAppDispatch } from '../app/hooks';
import React, {useState} from "react";
import { useForm } from "react-hook-form";
import {CreateAccountModel, useRegisterMutation} from "../api/psApi";
import {buttonStyle, errorStyle, inputStyle, labelStyle} from "../styles/baseStyles";
import {currentColors} from "../slices/style";
import { push } from "redux-first-history";
import {FormField} from "./FormField";
import { passwordStrength } from "check-password-strength";

export const validateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

export const validatePassword = (password) => {
    return 1 <= passwordStrength(password).id;
}

export function RegisterForm({ path, style }: { path?: string, style?: React.CSSProperties }) {
    const [registerPost, registerResult] = useRegisterMutation()
    const colors = useAppSelector(currentColors);
    const { register, handleSubmit, formState, watch, setError, clearErrors } = useForm({ 
        mode: "onChange", criteriaMode: "all", defaultValues: {} as CreateAccountModel & { passwordConfirm: string } });
    const dispatch = useAppDispatch();
    const [ errorMsg, updateErrorMsg ] = useState(null);
    // const [ passwordScore, updatePasswordScore ] = useState(0);
    const password = watch("password");

    const onSubmit = (data: CreateAccountModel & { passwordConfirm: string }) => {
        delete data.passwordConfirm;
        registerPost({ createAccountModel: data }).then((value: any) => {
            if(value.data) {
                dispatch(push(path || "/"));
                return Promise.resolve();
            } else{
                updateErrorMsg(value.error?.data || "Register failed");
            }
        });
    };
    
    return (
        <form onSubmit={handleSubmit(onSubmit)} style={{ 
            display: "grid",
            gridTemplateRows: "auto auto auto auto auto auto",
            columnGap: "calc(0.6vw + 6px)",
            rowGap: "calc(0.6vw + 6px)",
            ...style
        }}>
            <div style={{ gridRow: "1", gridColumn: "1", ...labelStyle, color: colors.text1 }}>Username: </div>
            <FormField style={{ gridRow: "1", gridColumn: "2 / 4" }} errors={formState.errors} name={"username"}>
                <input type={"text"} style={{ ...inputStyle }} {...register("username", { required: "This field is required"  })} />
            </FormField>
            <div style={{ gridRow: "2", gridColumn: "1", ...labelStyle, color: colors.text1 }}>Password: </div>
            <FormField style={{ gridRow: "2", gridColumn: "2 / 4" }} errors={formState.errors} name={"password"}>
                <input type={"password"} style={{ ...inputStyle }} {...register("password", {
                    required: "This field is required",
                    validate: value => validatePassword(value) ? undefined : "Password too weak"
                })} />
            </FormField>
            
            <div style={{ gridRow: "3", gridColumn: "1", ...labelStyle, color: colors.text1 }}>Password confimation: </div>
            <FormField style={{ gridRow: "3", gridColumn: "2 / 4" }} errors={formState.errors} name={"passwordConfirm"}>
                <input type={"passwordConfirm"} style={{ ...inputStyle }} {...register("passwordConfirm", { 
                    required: "This field is required",
                    validate: value => value === password || "The passwords do not match"  
                })} />
            </FormField>

            <div style={{ gridRow: "4", gridColumn: "1", ...labelStyle, color: colors.text1 }}>Email: </div>
            <FormField style={{ gridRow: "4", gridColumn: "2 / 4" }} errors={formState.errors} name={"email"}>
                <input type={"text"} style={{ ...inputStyle }} {...register("email", { required: "This field is required", validate: value => validateEmail(value) ? undefined : "Email is not valid"  })} />
            </FormField>
            
            <div style={{ gridRow: "5", gridColumn: "1", ...labelStyle, color: colors.text1 }}>Display name: </div>
            <FormField style={{ gridRow: "5", gridColumn: "2 / 4" }} errors={formState.errors} name={"displayName"}>
                <input type={"text"} style={{ ...inputStyle }} {...register("displayName", { required: "This field is required"  })} />
            </FormField>
            
            <div style={{
                ...errorStyle,
                gridRow: "6",
                gridColumn: "1 / 3",
                textAlign: "center"
            }}>{errorMsg}</div>
            <input style={{ gridRow: "6", gridColumn: "3", ...buttonStyle }} type="submit" value="Register" 
                   disabled={registerResult.isLoading || !formState.isValid} />
        </form>
    );
}