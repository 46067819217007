import React from "react";
import { titleStyle} from "../styles/baseStyles";
import {useAppDispatch, useAppSelector} from "../app/hooks";
import {currentColors} from "../slices/style";
import {useParams} from "react-router-dom";
import {useGetGroupByGroupIdQuery, useUpdateGroupMutation} from "../api/psApi";
import {Hearts} from "react-loader-spinner";
import {GroupLadies} from "./GroupLadies";
import {GroupLiveStream} from "./GroupLiveStream";
import {GroupForm} from "./GroupForm";
import {Loader} from "./Loader";

export function GroupUpdate({ style, groupId }: { style?: React.CSSProperties; groupId: number }) {
    const colors = useAppSelector(currentColors);
    const dispatch = useAppDispatch();
    
    const {
        data: groupData,
        isLoading: groupIsLoading,
        isFetching: groupIsFetching
    } = useGetGroupByGroupIdQuery({ groupId: groupId || 0 });
    
    const [ updateGroupPost, updateGroupState ] = useUpdateGroupMutation();
    
    if(groupIsLoading){
        return(
            <Loader height={"300px"} width={"300px"} />
        );
    }
    
    if(!groupData) {
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", height: "100%" }}>
            <div style={{ width: "100%", textAlign: "center", fontSize: "2vw" }}>
                Group not found
            </div>
        </div>
    }
    
    return (
        <div style={{
            ...style,
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            columnGap: "calc(1vw + 10px)",
            rowGap: "calc(0.5vw + 5px)"
        }}>
           <GroupForm style={{ width: "calc(20vw + 200px)" }}
                      type={"Update"}
                      onSubmit={(values) => updateGroupPost({ updateGroupModel: values })}
                      defaultValues={groupData} />
            <img style={{
                gridRow: "1 / 2",
                gridColumn: "1 / 3",
                height: "calc(4vw + 40px)",
                alignSelf: "flex-end"
            }} src={`/File/GroupPic?md5=${encodeURIComponent(groupData.imageMd5)}`} />
        </div>
    );
}