import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../app/store';
import {ColorPattern, colors1, colors2} from "../styles/colors";

export interface StyleState {
    colors: Array<ColorPattern>;
    colorIndex: number;
}

const storedColorIndex = parseInt(window.localStorage.getItem("colorIndex"));
const initialState: StyleState = {
    colors: [ colors1, colors2 ],
    colorIndex: storedColorIndex === 0 ? 0 : 1
};

export const accountSlice = createSlice({
    name: 'style',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        updateColor: (state, index: PayloadAction<number>) => {
            window.localStorage.setItem("colorIndex", index.payload.toString());
            state.colorIndex = index.payload;
        }
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    // extraReducers: (builder) => {
    //     builder
    //         ;
    // },
});

export const { updateColor  } = accountSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

export const currentColors = (state: RootState): ColorPattern => state.style.colors[state.style.colorIndex];
export const colorIndexSelector = (state: RootState): number => state.style.colorIndex;

export default accountSlice.reducer;
