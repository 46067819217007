import React from "react";
import {ReactComponent as YoutubeIcon} from "../icons/youtubeIcon.svg";
import {linkStyle} from "../styles/baseStyles";
import {ReactComponent as FacebookIcon} from "../icons/facebookIcon.svg";
import {StreamLinkModel} from "../api/psApi";


export function StreamLinkView({ streamLink, style, onClick }: {streamLink: StreamLinkModel; style?: React.CSSProperties; onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void }) {

    const iconStyle = { height: "calc(1vw + 10px)", width: "calc(1vw + 10px)" };
    const streamLinkStyle: React.CSSProperties = {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        columnGap: "0.5vw",
        fontSize: "calc(1vw + 10px)",
    }
    return (
        <div key={streamLink.streamLinkId} onClick={(e) => {
            if(onClick) {
                return onClick(e);
            }
        } } style={{
            ...streamLinkStyle,
            ...style
        }}>
            {streamLink.type === "Youtube"
            && <React.Fragment>
                <YoutubeIcon style={{ ...iconStyle }} />
                <a href={streamLink.url} style={linkStyle} target={"_blank"}>watch on {streamLink.name || "Youtube"}</a>
            </React.Fragment>}
            {streamLink.type === "Facebook"
            && <React.Fragment>
                <FacebookIcon style={{ ...iconStyle }} />
                <a href={streamLink.url} style={linkStyle} target={"_blank"}>watch on {streamLink.name || "Facebook"}</a>
            </React.Fragment>}
        </div>
    );
}