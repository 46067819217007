import React, {useState} from "react";
import {UpdateAccountModel} from "../api/psApi";
import {useAppSelector} from "../app/hooks";
import {currentColors} from "../slices/style";
import {useForm} from "react-hook-form";
import {buttonStyle, errorStyle, inputStyle, labelStyle} from "../styles/baseStyles";
import {FormField} from "./FormField";
import {validateEmail, validatePassword} from "./RegisterForm";

export function AccountForm({ style, onSubmit, defaultValues} : {
    style?: React.CSSProperties;
    onSubmit: (values : UpdateAccountModel) => Promise<any>;
    defaultValues: UpdateAccountModel;
}) {
    const colors = useAppSelector(currentColors);
    const {register, handleSubmit, formState, watch, resetField, control, reset} = useForm({
        mode: "onChange",
        criteriaMode: "all",
        defaultValues: defaultValues
    });
    const [errorMsg, updateErrorMsg] = useState("");
    
    const formOnSubmit = (values: UpdateAccountModel): Promise<any> => {
        return onSubmit(values).then(value => {
            updateErrorMsg("");
            if(value.data) {
                reset(value.data);
            } else{
                updateErrorMsg(value.error?.data || "Update failed");
            }
            return Promise.resolve(value);
        });
    }
    
    const newPassword = watch("newPassword");
    const email = watch("email");
    return (<form onSubmit={handleSubmit(formOnSubmit)} 
                  style={{
                      display: "grid",
                      gridTemplateRows: "auto auto auto auto auto auto",
                      gridTemplateColumns: "auto 1fr auto",
                      gridRowGap: "calc(0.5vw + 5px)",
                      gridColumnGap: "calc(0.6vw + 6px)",
                      ...style
                  }}>
        
        <div style={{gridRow: "1 / 2", gridColumn: "1 / 2", ...labelStyle}}>Username:</div>
        <FormField style={{gridRow: "1 / 2", gridColumn: "2 / 4"}} errors={formState.errors}
                   name={"username"}>
            <input type={"text"}
                   style={{ ...inputStyle }}
                   {...register("username", {
                       required: "This field is required"
                   })} />
        </FormField>

        <div style={{gridRow: "2 / 3", gridColumn: "1 / 2", ...labelStyle}}>Display name:</div>
        <FormField style={{gridRow: "2 / 3", gridColumn: "2 / 4"}} errors={formState.errors}
                   name={"displayName"}>
            <input type={"text"}
                   style={{ ...inputStyle }}
                   {...register("displayName", {
                       required: "This field is required"
                   })} />
        </FormField>

        <div style={{gridRow: "3 / 4", gridColumn: "1 / 2", ...labelStyle}}>Email:</div>
        <FormField style={{gridRow: "3 / 4", gridColumn: "2 / 4"}} errors={formState.errors}
                   name={"email"}>
            <input type={"text"}
                   style={{ ...inputStyle }}
                   {...register("email", {
                       required: "This field is required",
                       validate: value => validateEmail(value) ? undefined : "Email is not valid"
                   })} />
        </FormField>

        <div style={{gridRow: "4 / 5", gridColumn: "1 / 2", ...labelStyle}}>New password:</div>
        <FormField style={{gridRow: "4 / 5", gridColumn: "2 / 4"}} errors={formState.errors}
                   name={"newPassword"}>
            <input type={"password"}
                   style={{ ...inputStyle }}
                   {...register("newPassword", {
                       validate: value => !value || validatePassword(value) ? undefined : "Password too weak"
                   })} />
        </FormField>

        {(email != defaultValues.email || newPassword)
        && <React.Fragment>
            <div style={{gridRow: "5 / 6", gridColumn: "1 / 2", ...labelStyle}}>Current password:</div>
            <FormField style={{gridRow: "5 / 6", gridColumn: "2 / 4"}} errors={formState.errors}
                       name={"currentPassword"}>
                <input type={"password"}
                       style={{ ...inputStyle }}
                       {...register("currentPassword", {
                           required: "This field is required"
                       })} />
            </FormField>
        </React.Fragment>}

        <div style={{
            ...errorStyle,
            gridRow: "6 / 7",
            gridColumn: "1 / 3",
            textAlign: "center"
        }}>{errorMsg}</div>
        <input style={{ gridRow: "6 / 7", gridColumn: "3 / 4", ...buttonStyle }} 
               type="submit" 
               value="Update" 
               disabled={!formState.isValid} />
    </form>);
}