import {linkStyle} from "../styles/baseStyles";
import SmoothCollapse from "react-smooth-collapse";
import React, {useState} from "react";
import {ReactComponent as UpArrowIcon} from "../icons/upArrowIcon.svg";
import {ReactComponent as DownArrowIcon} from "../icons/downArrowIcon.svg";

export function Accordion({ children, title, isOpen, onChange, style }: {
    children: JSX.Element;
    title: string | JSX.Element;
    isOpen: boolean;
    onChange: (value) => void;
    style?: React.CSSProperties;
}) {
    const [ endIsOpen, updateEndIsOpen ] = useState(undefined)
    
    return (
        <div style={{ 
            display: "flex", 
            flexDirection: "column", 
            alignItems: "stretch", 
            rowGap: "calc(0.8vw + 10px)",
            padding: "calc(0.5vw + 5px) calc(0.8vw + 10px)",
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
            borderBottomRightRadius: "10px",
            borderBottomLeftRadius: "10px",
            boxSizing: "border-box",
            ...style 
        }}>
            <div style={{ 
                ...linkStyle, 
                fontSize: "calc(10px + 0.8vw)", 
                display: "flex", 
                flexDirection: "row", 
                alignItems: "center",
                columnGap: "calc(4px + 0.4vw)"
            }} 
                 onClick={(e) => onChange(!isOpen)}>
                {(endIsOpen === undefined ? isOpen : endIsOpen) ? <UpArrowIcon style={{ height: "1em", width: "1em" }} /> : <DownArrowIcon style={{ height: "1em", width: "1em" }} />}
                <div>{title}</div>
            </div>
            <SmoothCollapse expanded={isOpen} onAnimationEnd={() => updateEndIsOpen(isOpen)}>
                {children}
            </SmoothCollapse>
        </div>
    );
}