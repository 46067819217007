import React, {useState} from "react";
import {useGetChatSessionByStreamEventIdQuery} from "../api/psApi";
import {Hearts} from "react-loader-spinner";
import {Chat} from "./Chat";
import {Loader} from "./Loader";
import {useAppSelector} from "../app/hooks";
import {currentColors} from "../slices/style";

export function StreamChat({ style, streamEventId }: {  style?: React.CSSProperties, streamEventId: number}) {

    const {
        data: chatSessionData,
        isLoading: chatSessionIsLoading,
        isFetching: chatSessionIsFetching
    } = useGetChatSessionByStreamEventIdQuery({ streamEventId: streamEventId });
    
    if(chatSessionIsLoading){
        return(
            <Loader height={"100px"} width={"100px"} />
        );
    }
    
    if(!chatSessionData){
        return (
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", height: "100%" }}>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%" }}>
                    <div style={{ textAlign: "center" }}>Chat is disabled</div>
                </div>
            </div>
        );
    }
    
    return <Chat style={{ ...style }} chatSessionId={chatSessionData.chatSessionId} />;
}