import React, {useState} from "react";
import { Controller, Control } from "react-hook-form";
import Select, { Props, StylesConfig } from "react-select";
import {useAppSelector} from "../app/hooks";
import {currentColors} from "../slices/style";

type Option = { label: string; value: any; };
export function ControlSelect(props: Props & { options: Array<Option> } & { name: string, control: Control<any, any> }) {
    const colors = useAppSelector(currentColors);
    const selectStyles: StylesConfig = {
        control: styles => ({ 
            ...styles, 
            backgroundColor: colors.bg1, 
            color: colors.text1,
            padding: "0.1vw 0.2vw",
            minHeight: "0.8vw",
            fontSize: "0.7vw"
        }),
        input: styles => ({
            ...styles,
            padding: 0,
            margin: 0,
            width: "auto",
            color: colors.text1
        }),
        valueContainer: styles => ({
            ...styles,
            padding: 0
        }),
        indicatorsContainer: styles => ({
            ...styles,
        }),
        clearIndicator: styles => ({
            ...styles,
        }),
        indicatorSeparator: styles => ({
            ...styles,
            marginTop: "0.1vw",
            marginBottom: "0.1vw"
        }),
        singleValue: styles => ({
            ...styles,
            backgroundColor: colors.bg1,
            color: colors.text1,
            margin: 0
        }),
        dropdownIndicator: (styles, {  })=> ({
            ...styles,
            padding: 0
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                backgroundColor: colors.bg1, color: colors.text1,
                cursor: isDisabled ? 'not-allowed' : 'default',
            };
        }
    };
    
    return (<Controller name={props.name} 
                        control={props.control}
                        render={({ field }) => <Select {...props}
                                                   styles={selectStyles}
                                                   placeholder={""}
                                                   value={props.options.find(x => (x as any).value === field.value)}
                                                   onChange={(opt: Option) => field.onChange(opt.value)} />} />
    );
}