import React from "react";
import {smTitleStyle, titleStyle} from "../styles/baseStyles";
import {GroupLiveStream} from "./GroupLiveStream";
import {useParams} from "react-router-dom";


export function GroupStreamPortal({ style }: { style?: React.CSSProperties }) {
    const { groupId } = useParams();

    return(
        <div style={{
            ...style,
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
            rowGap: "calc(0.8vw + 8px)"
        }}>
            <div style={{ ...smTitleStyle }}>
                Stream portal
            </div>
            <div>
                <GroupLiveStream groupId={parseInt(groupId)} />
            </div>
        </div>
    );
}