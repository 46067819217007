import React from "react";
import {useAppSelector} from "../app/hooks";
import {currentColors} from "../slices/style";
import { GroupView } from "./GroupView";
import {pageStyle} from "../styles/baseStyles";

export function Home() {
    return (
        <div style={{ ...pageStyle, height: "100%" }}>
            <GroupView groupId={1} />
        </div>
    );
}