import React from "react";
import {activeLinkStyle, linkStyle, pageStyle} from "../styles/baseStyles";
import {NavLink, useParams} from "react-router-dom";
import { Outlet } from 'react-router-dom';
import {useAppSelector} from "../app/hooks";
import {currentColors} from "../slices/style";

const groupLinkStyle = { fontSize: "calc(0.7vw + 7px)" };
export function Group({ style }: { style?: React.CSSProperties }) {
    const { groupId } = useParams();
    const colors = useAppSelector(currentColors);

    return (
        <div style={{
            ...style,
        }}>
            <div style={{ 
                display: "flex", 
                flexDirection: "row", 
                alignItems: "center", 
                columnGap: "calc(1vw + 10px)",
                color: colors.text1,
                backgroundColor: colors.bg1,
                boxSizing: "border-box",
                borderTop: "1px solid",
                padding: "calc(0.1vw + 10px) calc(0.1vw + 20px)"
            }}>
                <NavLink style={({ isActive }) => ({ 
                    ...(isActive ? activeLinkStyle : linkStyle),
                    ...groupLinkStyle
                })}  to={`/group/mp/${groupId}`}>Group portal</NavLink>
                <NavLink style={({ isActive }) => ({ 
                    ...(isActive ? activeLinkStyle : linkStyle) ,
                    ...groupLinkStyle
                })} to={`/group/mp/${groupId}/stream`}>Stream portal</NavLink>
                <NavLink style={({ isActive }) => ({
                    ...(isActive ? activeLinkStyle : linkStyle) ,
                    ...groupLinkStyle
                })} to={`/group/mp/${groupId}/payment`}>Payment portal</NavLink>
            </div>
            <div style={{ ...pageStyle }}>
                <Outlet />
            </div>
        </div>
    );
}