import React, { useState} from "react";
import {linkStyle} from "../styles/baseStyles";
import {useAppDispatch, useAppSelector} from "../app/hooks";
import {currentColors} from "../slices/style";
import {
    CreateLadyModel,
    UpdateLadyModel,
    useCreateLadyMutation,
    useListLadyByGroupIdQuery,
    useUpdateLadyMutation
} from "../api/psApi";
import {Hearts} from "react-loader-spinner";
import {Lady} from "./Lady";
import {usePopper} from "react-popper";
import {LadyForm} from "./LadyForm";
import {ReactComponent as EditIcon} from "../icons/editIcon.svg";
import {Loader} from "./Loader";

export function GroupLadies({ style, groupId }: { style?: React.CSSProperties, groupId: number }) {
    const colors = useAppSelector(currentColors);
    const dispatch = useAppDispatch();
    
    const [addLadyOpened, updateAddLadyOpened] = useState(false);
    const [addLadyRef, setAddLadyRef] = useState(null);
    const [addLadyPopperElement, setAddLadyPopperElement] = useState(null);
    const { styles: stylesAdd, attributes: attributesAdd } = usePopper(addLadyRef, addLadyPopperElement, {
        placement: "right-start",
    });

    const [updateLadyOpened, updateUpdateLadyOpened] = useState({} as { [ladyId: number]: boolean });
    const [updateLadyRef, setUpdateLadyRef] = useState(null);
    const [updateLadyPopperElement, setUpdateLadyPopperElement] = useState(null);
    const {styles: stylesUpdate, attributes: attributesUpdate } = usePopper(updateLadyRef, updateLadyPopperElement, {
        placement: "right-start",
    });
    
    const {
        data: ladiesData,
        isLoading: ladiesIsLoading,
        isFetching: ladiesIsFetching
    } = useListLadyByGroupIdQuery({groupId: groupId});

    const [ createLadyPost, createLadyState ] = useCreateLadyMutation();
    const [ updateLadyPost, updateLadyState ] = useUpdateLadyMutation();
    
    if (ladiesIsLoading) {
        return(
            <Loader height={"300px"} width={"300px"} />
        );
    }

    if (!ladiesData) {
        <div style={{display: "flex", flexDirection: "row", alignItems: "center", height: "100%"}}>
            <div style={{width: "100%", textAlign: "center", fontSize: "2vw"}}>
                No data
            </div>
        </div>
    }
    
    return (
        <div style={{
            ...style,
            display: "flex", flexDirection: "column",
            rowGap: "1vw"
        }}>
            <div>
                <div style={{display: "flex", flexDirection: "row"}}>
                    <div ref={setAddLadyRef} style={{...linkStyle, fontSize: "calc(0.8vw + 8px)"}}
                         onClick={(e) => updateAddLadyOpened(!addLadyOpened)}>
                        + Add Lady
                    </div>
                </div>
                {addLadyOpened && <div ref={setAddLadyPopperElement}
                                       style={{
                                           ...stylesAdd.popper,
                                           left: "0.5vw",
                                           color: colors.text2,
                                           backgroundColor: colors.bg2,
                                           padding: "0.5vw",
                                           zIndex: 10,
                                           border: `1px solid ${colors.text1}`
                                       }} {...attributesAdd.popper}>
                    <LadyForm type={"Create"} onSubmit={(values: CreateLadyModel) => {
                            values.groupId = groupId;
                            createLadyPost({ createLadyModel: values })
                            .then(d => {
                                updateAddLadyOpened(false);
                            });
                        }}
                        onRequestClose={() => updateAddLadyOpened(false)} />
                </div>}
            </div>
            <div style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                columnGap: "0.3vw"
            }}>
                {ladiesData.map(lady => <div key={lady.ladyId} style={{ position: "relative" }}>
                    <EditIcon style={{ 
                        position: "absolute", 
                        top: "5px", 
                        right: "5px", 
                        color: colors.text1,
                        width: "calc(0.5vw + 5px)",
                        backgroundColor: colors.bg1,
                        ...linkStyle
                    }} onClick={(e) => updateUpdateLadyOpened({ [lady.ladyId]: true })} />
                    {updateLadyOpened[lady.ladyId] && <span ref={setUpdateLadyRef}>
                    </span>}
                    {updateLadyOpened[lady.ladyId] 
                        && <div ref={setUpdateLadyPopperElement}
                               style={{
                                   ...stylesUpdate.popper,
                                   color: colors.text2,
                                   backgroundColor: colors.bg2,
                                   padding: "0.5vw",
                                   zIndex: 10,
                                   border: `1px solid ${colors.text1}`
                               }} {...attributesUpdate.popper}>
                        <LadyForm type={"Update"} onSubmit={(values: UpdateLadyModel) => {
                            updateLadyPost({ updateLadyModel: values })
                                .then(d => {
                                    updateUpdateLadyOpened({});
                                });
                            }}
                                  defaultValues={lady}
                                  onRequestClose={() => updateUpdateLadyOpened({})} />
                    </div>}
                    <Lady style={{}} lady={lady}/>
                </div>)}
            </div>
        </div>
    );
}