import React from 'react';
import './App.css';
import { Route, Routes } from "react-router-dom";
import {Home} from "./components/Home";
import {Login} from "./components/Login";
import {Register} from "./components/Register";
import {StreamEvent} from "./components/StreamEvent";
import {Group} from "./components/Group";
import {GroupStreamPortal} from "./components/GroupStreamPortal";
import {GroupPortal} from "./components/GroupPortal";
import {GroupPaymentPortal} from "./components/GroupPaymentPortal";
import {GroupStreams} from "./components/GroupStreams";
import {Layout} from "./components/Layout";
import {GroupViewPage} from "./components/GroupViewPage";
import {ChatPopout} from "./components/ChatPopout";
import {AccountPortal} from "./components/AccountPortal";
import {RecoverAccount} from "./components/RecoverAccount";
import {ResetPassword} from "./components/ResetPassword";

function App() {
    return (
        <Routes>
            <Route path="/" element={<Layout />}>
                <Route path="/" element={<Home />}/>
                <Route path="/register" element={<Register />}/>
                <Route path="/login" element={<Login/>}/>
                <Route path="/login/*:path" element={<Login />}/>
                <Route path="/streams" element={<GroupStreams />}/>
                <Route path="/account" element={<AccountPortal />}/>
                <Route path="/account/recover" element={<RecoverAccount />}/>
                <Route path="/account/reset/:token" element={<ResetPassword />}/>
                <Route path="/stream/:groupId" element={<StreamEvent />}/>
                <Route path="/group/:groupId" element={<GroupViewPage />}/>
                <Route path="/group/mp/:groupId" element={<Group />}>
                    <Route path="/group/mp/:groupId" element={<GroupPortal />}/>
                    <Route path="/group/mp/:groupId/stream" element={<GroupStreamPortal />}/>
                    <Route path="/group/mp/:groupId/payment" element={<GroupPaymentPortal />}/>
                </Route>
                <Route path="/group/:groupId/stream" element={<GroupStreamPortal />}/>
            </Route>
            <Route path="/chat/:groupId" element={<ChatPopout />}/>
        </Routes>
    );
}

export default App;
