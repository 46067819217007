import { useAppSelector, useAppDispatch } from '../app/hooks';
import React, {useState} from "react";
import { useForm } from "react-hook-form";
import {LoginModel, useLoginMutation} from "../api/psApi";
import {buttonStyle, errorStyle, inputStyle, labelStyle} from "../styles/baseStyles";
import {currentColors} from "../slices/style";
import { push } from "redux-first-history";
import {FormField} from "./FormField";
import {useParams} from "react-router-dom";

export function LoginForm({ style }: { style?: React.CSSProperties }) {
    const [loginPost, { isLoading:  loginPostIsLoading, data: currentUser }] = useLoginMutation();
    const { path } = useParams();
    const colors = useAppSelector(currentColors);
    const { register, handleSubmit, formState } = useForm({ mode: "onChange", criteriaMode: "all" });
    const [ errorMsg, updateErrorMsg ] = useState(null);
    const dispatch = useAppDispatch();
    const onSubmit = (data: LoginModel) => {
        loginPost({ loginModel: data }).then((value: any) => {
            if(value.data) {
                dispatch(push(path || "/"));
                return Promise.resolve();
            } else{
                updateErrorMsg(value.error?.data || "Login failed");
            }
        });
    };
    
    return (
        <form onSubmit={handleSubmit(onSubmit)} style={{ 
            display: "grid",
            gridTemplateRows: "auto auto auto",
            gridTemplateColumns: "auto 1fr auto",
            columnGap: 15,
            rowGap: 15,
            ...style
        }}>
            <div style={{ gridRow: "1", gridColumn: "1", ...labelStyle, color: colors.text1 }}>Username: </div>
            <FormField style={{ gridRow: "1", gridColumn: "2 / 4" }} errors={formState.errors} name={"username"}>
                <input type={"text"} style={{ ...inputStyle }} {...register("username", { required: "This field is required"  })} />
            </FormField>
            <div style={{  gridRow: "2", gridColumn: "1", ...labelStyle, color: colors.text1 }}>Password: </div>
            <FormField style={{ gridRow: "2", gridColumn: "2 / 4" }} errors={formState.errors} name={"password"}>
                <input type={"password"} style={{ ...inputStyle }} {...register("password", { required: "This field is required"  })} />
            </FormField>
            <div style={{ 
                ...errorStyle, 
                gridRow: "3 / 4", 
                gridColumn: "1 / 3",
                textAlign: "center"
            }}>{errorMsg}</div>
            <input style={{ gridRow: "3 / 4", gridColumn: "3 / 4", ...buttonStyle }} type="submit" value="Login" disabled={loginPostIsLoading || !formState.isValid} />
        </form>
    );
}