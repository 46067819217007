import React from "react";
import {useListLiveStreamEventQuery} from "../api/psApi";
import {useAppDispatch, useAppSelector} from "../app/hooks";
import {push} from "redux-first-history";
import {currentColors} from "../slices/style";
import {VideoPlayer} from "./VideoPlayer";
import {Loader} from "./Loader";

export function StreamEvents({ style }: {  style?: React.CSSProperties}) {
    const dispatch = useAppDispatch();
    const {
        data: listData,
        isFetching: listIsFetching,
        isLoading: listIsLoading,
    } = useListLiveStreamEventQuery();
    const colors = useAppSelector(currentColors);
    
    if(listIsLoading){
        return(
            <Loader height={"300px"} width={"300px"} />
        );
    }
    if(!listData) {
        return (<div style={{ display: "flex", flexDirection: "row", alignItems: "center", height: "100%" }}>
            <div style={{ width: "100%", textAlign: "center", fontSize: "2vw" }}>
               Error loading data
            </div>
        </div>);
    }
    
    return (
        <div style={{ display: "flex", flexDirection: "row", alignItems: "start", flexWrap: "wrap", ...style }}>
            {listData.map(stream => <div key={stream.streamEventId} style={{ 
                color: colors.text1,
                display: "grid",
                gridTemplateRows: "auto auto",
                gridTemplateColumns: "1fr auto",
                cursor: "pointer"
            }}
            onClick={(e) => {
                dispatch(push(`/stream/${stream.groupId}`));
            }}>
                {stream.type !== "Offsite" 
                && <VideoPlayer style={{ gridRow: "1 / 2", gridColumn: "1 / 3" }}
                                playerProps={{
                                    url: stream.liveEndpointDash,
                                    controls: false,
                                    playing: false,
                                    light: `/File/GroupPic?md5=${stream.imageMd5}`,
                                    width: 350,
                                    height: 196.88
                                }} />}
                {stream.type === "Offsite"
                && <img style={{
                    gridRow: "1 / 2",
                    gridColumn: "1 / 3",
                    width: "350px",
                    height: "196.88px"
                }} src={`/File/GroupPic?md5=${encodeURIComponent(stream.imageMd5)}`} />}
                <div style={{ gridRow: "2 / 3", gridColumn: "1 / 2", textAlign: "center" }}>
                    {stream.title}
                </div>
                <div style={{ gridRow: "2 / 3", gridColumn: "2 / 3" }}>
                    
                </div>
            </div>)}
        </div>
    );
}