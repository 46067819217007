import React, {useEffect, useRef, useState} from "react";
import {useAppDispatch, useAppSelector} from "../app/hooks";
import {useGetChatMessagesByChatSessionIdQuery} from "../api/chatApi";
import {useCreateChatMessageMutation, useCurrentLoginQuery} from "../api/psApi";
import {useSelector} from "react-redux";
import {linkStyle} from "../styles/baseStyles";
import {Loader} from "./Loader";
import {currentColors} from "../slices/style";
import {ReactComponent as BubbleIcon} from "../icons/bubbleIcon.svg";
import {ReactComponent as EmojiIcon} from "../icons/emojiIcon.svg";
import {ReactComponent as CloseIcon} from "../icons/closeIcon.svg";
import EmojiPicker from 'emoji-picker-react';
import ContentEditable from 'react-contenteditable'
import {usePopper} from "react-popper";
import {ChatView} from "./ChatView";
import {isAuthenticated} from "../slices/account";

export function Chat({ style, chatSessionId }: {  style?: React.CSSProperties, chatSessionId: number}) {
    const dispatch = useAppDispatch();
    const [chatBoxValue, updateChatBoxValue] = useState("");
    const chatBoxRef = useRef(null);
    const divScrollRef = useRef(null);
    const colors = useAppSelector(currentColors);

    const {
        data: chatMessages,
        isFetching: chatMessagesFetching,
        isLoading: chatMessagesLoading,
    } = useGetChatMessagesByChatSessionIdQuery({chatSessionId: chatSessionId});
    const {
        data: currentLogin,
        isFetching: currentLoginFetching,
        isLoading: currentLoginLoading,
    } = useCurrentLoginQuery();
    
    const [
        createChatPost, createChatResult
    ] = useCreateChatMessageMutation();

    const [emojiPickerOpened, updateEmojiPickerOpened] = useState(false);
    const [emojiPickerRef, updateEmojiPickerRef] = useState(null);
    const [emojiPickerPopper, updateEmojiPickerPopper] = useState(null);
    const { styles: stylesEmojiPicker, attributes: attributesEmojiPicker } = usePopper(emojiPickerRef, emojiPickerPopper, {
        placement: "top-end",
    });
    
    const createMessage = () => {
        createChatPost({
            createChatMessageModel: {
                chatSessionId: chatSessionId,
                content: chatBoxValue
            }
        });
        updateChatBoxValue("");
    };

    const scrollToBot = () => {
        if (divScrollRef && divScrollRef.current) {
            // console.log("Scrolling to top");
            // console.log(`Scroll height: ${divScrollRef.current.scrollHeight}`);
            //divScrollRef.current.scrollTop = divScrollRef.current.scrollHeight;
            divScrollRef.current.scrollTo(0, divScrollRef.current.scrollHeight);
        }
    }

    useEffect(() => {
        if (chatBoxRef && chatBoxRef.current) {
            const onKeyDown = (e) => {
                const keyCode = e.which || e.keyCode;
                // 13 Enter key
                if (keyCode === 13 && !e.shiftKey) {
                    createMessage();
                    e.preventDefault();
                }
            };
            // console.log("+keydown");
            chatBoxRef.current.addEventListener('keydown', onKeyDown);

            return () => {
                // console.log("-keydown");
                chatBoxRef.current?.removeEventListener('keydown', onKeyDown)
            };
        }
    });

    useEffect(() => {
        scrollToBot();
    });


    if (chatMessagesLoading) {
        return (
            <Loader height={"100px"} width={"100px"}/>
        );
    }

    return (
        <div style={{display: "grid", gridTemplateRows: "1fr auto", gridTemplateColumns: "1fr", ...style}}>
            <ChatView style={{
                gridRow: "1 / 2",
                gridColumn: "1 / 2"
            }} divScrollRef={divScrollRef} chatMessages={chatMessages} />
            <div style={{
                gridRow: "2 / 3", gridColumn: "1 / 2",
                display: "flex",
                flexDirection: "column",
                alignItems: "stretch",
                rowGap: "calc(0.2vw + 2px)",
                boxSizing: "border-box",
                padding: "calc(0.3vw + 3px) calc(0.6vw + 6px)"
            }}>
                {currentLoginLoading && <Loader height={"50px"} width={"50px"} />}
                {!currentLoginLoading && <React.Fragment>
                    {isAuthenticated(currentLogin)
                    && <React.Fragment>
                        <div ref={updateEmojiPickerRef} style={{display: "flex", flexDirection: "row", flex: "1"}}>
                            <ContentEditable
                                innerRef={chatBoxRef}
                                style={{
                                    display: "block",
                                    flex: "1",
                                    padding: "calc(0.1vw + 1px) calc(0.2vw + 2px)",
                                    boxSizing: "border-box",
                                    border: `1px solid ${colors.text3}`,
                                    fontSize: "1em",
                                    borderRadius: "4px"
                                }}
                                html={chatBoxValue}
                                onChange={(e) => updateChatBoxValue(e.target.value)}/>
                        </div>
                        <div style={{flex: "0 0 auto", display: "flex", flexDirection: "row-reverse", columnGap: "calc(1vw + 10px)" }}>
                            <BubbleIcon style={{...linkStyle, width: "calc(0.6vw + 6px)", height: "calc(0.6vw + 6px)"}}
                                        onClick={(e) => {
                                            if (!createChatResult.isLoading) {
                                                createMessage();
                                            }
                                        }}
                            />
                            <EmojiIcon style={{...linkStyle, width: "calc(0.6vw + 6px", height: "calc(0.6vw + 6px)"}}
                                       onClick={(e) => updateEmojiPickerOpened(!emojiPickerOpened)}/>
                            {emojiPickerOpened && <div ref={updateEmojiPickerPopper}
                                                       style={{
                                                           ...stylesEmojiPicker.popper,
                                                           zIndex: 10,
                                                       }} {...attributesEmojiPicker.popper}>
                                <CloseIcon style={{
                                    ...linkStyle,
                                    color: colors.bg1,
                                    width: "calc(0.5vw + 5px)",
                                    height: "calc(0.5vw + 5px)",
                                    position: "absolute",
                                    zIndex: 11,
                                    top: 5,
                                    right: 5
                                }} onClick={(e) => updateEmojiPickerOpened(false)} />
                                <EmojiPicker width={"calc(7vw + 70px)"} height={"calc(12vw + 120px)"}
                                             onEmojiClick={(emoji, e) => {
                                                 console.log(e);
                                                 updateChatBoxValue(chatBoxValue + emoji.emoji);
                                                 if(chatBoxRef && chatBoxRef.current) {
                                                     chatBoxRef.current.focus();
                                                 }
                                             }} />
                            </div>}
                        </div>
                    </React.Fragment>}
                    {!isAuthenticated(currentLogin)
                    && <div style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        flex: "1",
                        color: colors.text1,
                        backgroundColor: colors.bg1
                    }}>
                        <div style={{textAlign: "center", width: "100%"}}>Login to chat here</div>
                    </div>}
                </React.Fragment>}
            </div>
        </div>
    );
}