import React from "react";
import {Hearts} from "react-loader-spinner";
import {useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../app/hooks";
import {
    useGetLiveStreamEventByGroupIdQuery,
} from "../api/psApi";
import {push} from "redux-first-history";
import {currentColors} from "../slices/style";
import StreamEventView from "./StreamEventView";
import {Loader} from "./Loader";
import {pageStyle} from "../styles/baseStyles";

export function StreamEvent({ style }: {  
    style?: React.CSSProperties
}) {
    const dispatch = useAppDispatch();
    const {groupId} = useParams();
    const colors = useAppSelector(currentColors);

    if (!groupId
        || isNaN(parseInt(groupId))) {
        dispatch(push("/"));
        return <div>ERROR</div>;
    }

    const {
        data: streamData,
        isFetching: streamIsFetching,
        isLoading: streamIsLoading,
    } = useGetLiveStreamEventByGroupIdQuery({groupId: parseInt(groupId)});

    if (streamIsLoading) {
        return(
            <Loader height={"300px"} width={"300px"} />
        );
    }

    if (!streamData)
        return (
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", height: "100%" }}>
                <div style={{ width: "100%", textAlign: "center", fontSize: "2vw" }}>
                    Sorry this stream is offline
                </div>
            </div>
        );
    
    return (
        <div style={{ ...pageStyle, height: "100%", boxSizing: "border-box" }}>
            <StreamEventView stream={streamData} withChat={streamData.type !== "Offsite"} withOrder={true} />
        </div>
    );
}