import React, {useEffect, useRef} from "react";
import ReactPlayer, { ReactPlayerProps } from 'react-player'

export function VideoPlayer({ style, playerProps }
                                : {  
    style?: React.CSSProperties;
    playerProps: ReactPlayerProps;
}) {
    return (
        <ReactPlayer style={{ ...style }} config={{
            file: {
                forceHLS: true
            }
        }} {...playerProps} />
    );
}