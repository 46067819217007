import { useAppSelector } from '../app/hooks';
import React, {useRef} from "react";
import { useForm } from "react-hook-form";
import {UpdateGroupModel} from "../api/psApi";
import {inputStyle, labelStyle, linkStyle} from "../styles/baseStyles";
import {currentColors} from "../slices/style";
import {FormField} from "./FormField";

type GroupFormType = "Create" | "Update";
export function GroupForm({ type, style, defaultValues, onSubmit }
                              : { 
    type: GroupFormType; 
    style?: React.CSSProperties; 
    defaultValues: UpdateGroupModel ;
    onSubmit: (values: UpdateGroupModel) => void;
}) {
    const imgFileRef = useRef(null);
    const colors = useAppSelector(currentColors);
    const { register, handleSubmit, formState, watch, resetField } = useForm({ 
        mode: "onChange", 
        criteriaMode: "all",
        defaultValues: defaultValues as UpdateGroupModel & { imageInput: any }
    });
    const formSubmit = (data: UpdateGroupModel& { imageInput: Array<File> }) => {
        if(data.imageInput && data.imageInput[0]) {
            let reader = new FileReader();
            reader.readAsDataURL(data.imageInput[0]);
            reader.onload = function () {
                data.imageBase64 = (reader.result as string).split(",")[1];
                data.imageFilename = data.imageInput[0].name;
                delete data.imageInput;
                onSubmit(data);
            };
        } else {
            delete data.imageInput;
            onSubmit(data);
        }

    };

    const addImageInput = watch("imageInput");
    const addImageRegister = register("imageInput", {});

    return (
        <form onSubmit={handleSubmit(formSubmit)} style={{
            display: "grid",
            gridTemplateRows: "auto auto auto",
            gridTemplateColumns: "auto 1fr auto",
            columnGap: 15,
            rowGap: 15,
            ...style
        }}>
            <div style={{ gridRow: "1 / 2", gridColumn: "1", ...labelStyle, color: colors.text1 }}>Name: </div>
            <FormField style={{ gridRow: "1 / 2", gridColumn: "2 / 4" }} errors={formState.errors} name={"name"}>
                <input type={"text"} style={{ ...inputStyle }} {...register("name", { required: "This field is required"  })} />
            </FormField>
            <div style={{ gridRow: "2 / 3", gridColumn: "1", ...labelStyle, color: colors.text1 }}>Description: </div>
            <FormField style={{ gridRow: "2 / 3", gridColumn: "2 / 4" }} errors={formState.errors} name={"description"}>
                <textarea style={{ width: "100%", height: "calc(5vw + 50px)" }} {...register("description", { required: "This field is required"  })}>
                </textarea>
            </FormField>
            <div style={{gridRow: "3 / 4", gridColumn: "1 / 2", ...labelStyle}}>Picture:</div>
            <FormField style={{gridRow: "3 / 4", gridColumn: "2 / 4"}} errors={formState.errors}
                       name={"imageInput"}>
                <div>
                    <input ref={imgFileRef}
                           style={{visibility: addImageInput && addImageInput.length !== 0 ? "collapse" : "visible"}}
                           type={"file"}
                           {...addImageRegister}/>
                    {addImageInput
                    && addImageInput.length !== 0
                    &&
                    <div style={{display: "flex", flexDirection: "row", alignItems: "flex-start", columnGap: "0.2vw"}}>
                        <img src={URL.createObjectURL(addImageInput[0])}
                             alt="preview (no processing)"
                             style={{maxWidth: 128, maxHeight: 128}}
                        />
                        <div style={{...linkStyle, fontSize: "1vw"}}
                             onClick={(e) => {
                                 resetField("imageInput");
                             }}>X
                        </div>
                    </div>}
                </div>
            </FormField>
            <input style={{ gridRow: "3", gridColumn: "3" }} type="submit" value="Update" disabled={!formState.isValid} />
        </form>
    );
}